import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { ApiUserService } from '../../services/apiUserService';
import { useAlert } from '../../context/generic/AlertContext';

interface ResetPasswordProps {
  create?: boolean
}
const ResetPassword: React.FC<ResetPasswordProps> = ({create}) => {
  const apiUserService = new ApiUserService();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (password !== confirmPassword) {
      showAlert("Les mots de passe ne correspondent pas", "error");
      return;
    }

    // Extrait le token de réinitialisation du mot de passe de l'URL
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    
    if (!token) {
      showAlert("Token de réinitialisation manquant", "error");
      return;
    }

    try {
      await apiUserService.resetPassword(token, password); // Assure-toi d'implémenter cette fonction dans ton service
      showAlert(create ? "Mot de passe créé avec succès" : "Mot de passe réinitialisé avec succès", "success");
      navigate('/login');
    } catch (error) {
      showAlert(create ? "Une erreur s'est produite lors de la création du mot de passe" :  "Une erreur s'est produite lors de la réinitialisation du mot de passe", "error");
    }
  };

  return (
    <div className="reset-password-container">
      <form onSubmit={handleSubmit}>
        <h2>{create ? "Créer un mot de passe" : "Réinitialiser le mot de passe"}</h2>
        <div className="form-element-container">
          <label htmlFor="password">Nouveau mot de passe</label>
          <input
            id="password"
            type="password"
            placeholder="Nouveau mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-element-container">
          <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
          <input
            id="confirmPassword"
            type="password"
            placeholder="Confirmer le mot de passe"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-submit-container">
          <button type="submit">{create ? "Créer le mot de passe" : "Réinitialiser le mot de passe" }</button>
        </div>
      </form>
    </div>
  );
};

export default ResetPassword;
