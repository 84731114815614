import React, { useEffect, useState } from "react";
import PartnersTable from "./PartnersTable";
import { AutomationExecutionStatusEnum, PartnerInterface, SummaryAutomationExecutionInterface } from "../../../interfaces/PartnerInterface";
import { faPlay, faStop, faVial } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiService } from "../../../services/generic/apiService";
import "./PartnersList.css";
import AccountManagerSelect from "../../Manager/Form/AccountManagerSelect";
import { FormProvider, useForm } from "react-hook-form";

interface PartnersListProps {
  mainActions?: boolean;
  simulateAction?: boolean;
  canPlay: (partner?: PartnerInterface | null) => boolean;
  onPlay: (partner?: PartnerInterface) => void;
  onSimulate?: (partner?: PartnerInterface) => void;
  onShowLogs: (partner: PartnerInterface) => void;
  onStop: (partner?: PartnerInterface) => void
  getPartners: (userId?: string) => void;
  partners: PartnerInterface[];
  selectedManager: string;
  setSelectedManager: (userId: string) => void;
}

const PartnersList: React.FC<PartnersListProps> = ({
  mainActions = true,
  simulateAction = true,
  canPlay,
  onPlay,
  onSimulate = () => { },
  onShowLogs,
  onStop,
  getPartners,
  partners,
  selectedManager,
  setSelectedManager,
}) => {
  // State hooks
  const methods = useForm();

  useEffect(() => {
    getPartners(selectedManager);
  }, []);

  useEffect(() => {
    getPartners(selectedManager);
  }, [selectedManager]);

  const handleManagerChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedManager(event.target.value);
  };

  return (
    <>
      <FormProvider {...methods}>
        <AccountManagerSelect
          name="userId"
          label="Account Manager"
          emptyLabel={"Tous les account manager"}
          onChange={handleManagerChange}
        />
      </FormProvider>
      {mainActions && (
        <div>
          {
            <button onClick={() => onPlay()}>
              <FontAwesomeIcon icon={faPlay} />
            </button>
          }
          {
            <button className="btn-delete" onClick={() => onStop()}>
              <FontAwesomeIcon icon={faStop} />
            </button>
          }
          {
            simulateAction && (
              <button onClick={() => onSimulate()}>
                <FontAwesomeIcon icon={faVial} />
              </button>
            )}
        </div>
      )
      }

      <PartnersTable
        simulateAction={simulateAction}
        canPlay={canPlay}
        partners={partners}
        onPlay={onPlay}
        onSimulate={onSimulate}
        onShowLogs={onShowLogs}
        onStop={onStop}
      />
    </>
  );
};

export default PartnersList;
