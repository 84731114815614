import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import InputField from "../../generic/Form/Fields/Input";
import PartnerSelect from "../../Partner/Forms/PartnerSelect";
import { EmailConfigurationInterface } from "../../../interfaces/EmailConfigurationInterface";

interface Props {
  emailConfiguration?: EmailConfigurationInterface | null;
  onSubmit: (data: any) => void;
}

interface EmailConfigurationFormValues {
  partnerId: string;
  automation: string;
  subject: string;
  cc: string;
}

const EmailConfigurationFormEdit: React.FC<Props> = ({ onSubmit, emailConfiguration }) => {
  const methods = useForm<EmailConfigurationFormValues>({
    defaultValues: {
      partnerId: "",
      automation: "",
      subject: "",
      cc: "",
    }
  });

  React.useEffect(() => {
    methods.reset({
      partnerId: emailConfiguration?.partner?.id.toString() ?? "",
      automation: emailConfiguration?.automation ?? "",
      subject: emailConfiguration?.subject ?? "",
      cc: emailConfiguration?.cc ?? "",
    });
  }, [emailConfiguration, methods]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="form-grid">
        <PartnerSelect
          name="partnerId"
          label="Partenaire"
          defaultValue={emailConfiguration?.partner?.id.toString()}
          disabled={true} 
        />
        <InputField
          name="partnerId"
          type="hidden"
          disabled={true} 
        />
        <InputField
          name="automation"
          label="Automatisation"
          type="text"
          disabled={true} 
        />
        <InputField
          name="subject"
          label="Sujet"
          type="text"
        />
        <InputField
          name="cc"
          label="CC"
          type="text"
        />

        <div className="buttons-container">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EmailConfigurationFormEdit;
