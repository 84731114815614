import ShopPerformancesList from "../../components/ShopPerformance/ShopPerformanceList";
import "./ShopPerformances.css";

function ShopPerformances() {
  return (
    <>
      <div className="imports-container">
        <h3>Liste des commandes</h3>
        <ShopPerformancesList />
      </div>
    </>
  );
}

export default ShopPerformances;
