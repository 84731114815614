import PartnersList from "../../components/Partner/PartnersList";
import "./Partners.css";

function Partners() {
  return (
    <>
      <div className="partners-container">
        <h3>Gestion des partenaires</h3>
        <PartnersList />
      </div>
    </>
  );
}

export default Partners;
