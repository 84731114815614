import { ApiService, request } from "./generic/apiService";
import { ShopInterface } from "../interfaces/ShopInterface";

export class ApiShopService extends ApiService<ShopInterface, ShopInterface> {
  constructor() {
    super(`/shops`);
  }

  getProviders = async (): Promise<string[]> => {
    try {
      let response = await request({
        url: `${this.basePath}/providers`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };

  search = async (searchTerm: string, page: number = 1, perPage: number = 10): Promise<ShopInterface[]> => {
    try {
      const response = await request({
        url: `${this.basePath}`,
        method: 'GET',
        params: {
          query: searchTerm,
          page,
          perPage,
        },
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  };
}