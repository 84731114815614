import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../generic/Modal/Modal";
import { PartnerInterface } from "../../../interfaces/PartnerInterface";
import PartnerFormEdit from "../Forms/PartnerFormEdit";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  partner?: PartnerInterface | null;
}

const PartnerPushModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  partner,
}) => {
  const methods = useForm();

  React.useEffect(() => {
    methods.reset({
      name: !partner ? "" : partner.name,
      email: !partner ? "" : partner.email,
      isMajorRetailer: !partner ? false : partner.isMajorRetailer,
      comment: !partner ? "" : partner.comment,
    });
  }, [partner]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        partner ? "Modifier un partenaire" : "Ajouter un partenaire"
      }
      size="large"
    >
      <PartnerFormEdit partner={partner} onSubmit={onSubmit} />
    </Modal>
  );
};

export default PartnerPushModal;
