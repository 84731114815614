import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpBackend from 'i18next-http-backend';

i18n
  .use(HttpBackend) // passez i18next-http-backend à i18next
  .use(initReactI18next) // init react-i18next
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json' // Chemin d'accès aux fichiers JSON
    },
    fallbackLng: 'fr',
    lng: 'fr', // Langue par défaut
    ns: ['report', 'providers', 'automations'],
    defaultNS: 'report',
    interpolation: {
      escapeValue: false // React échappe déjà les XSS
    }
  });

export default i18n;
