import React from 'react';
import Modal from '../../../generic/Modal/Modal';
import { UserInterface } from '../../../../interfaces/UserInterface';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  user: UserInterface | null;
}

const UserDeleteConfirmationModal: React.FC<Props> = ({ isOpen, onClose, onDelete, user }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirmez vous cette action ?" size="large">
      <div>
        Confirmez-vous la suppression de l'utilisateur "{user?.login}" ?
        <div className="buttons-container">
          <button className="btn-delete" onClick={onDelete}>
            Confirmer
          </button>
          <button onClick={onClose}>Annuler</button>
        </div>
      </div>
    </Modal>
  );
};

export default UserDeleteConfirmationModal;
