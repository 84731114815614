import React, { useEffect } from 'react'
import { useFormContext, RegisterOptions } from 'react-hook-form'

export interface Option {
  value: string
  label: string
}

interface SelectFieldProps {
  name: string
  label?: string
  options: Option[]
  defaultValue?: string
  validationRules?: RegisterOptions
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  disabled?: boolean; // Ajout de la prop disabled
}

const SelectField: React.FC<SelectFieldProps> = ({ name, label, options, defaultValue, validationRules, onChange, disabled }) => {
  const { register, setValue, formState: { errors } } = useFormContext()

  const errorMessage = (name: string): string | null => {
    const error = errors[name]
    return error && typeof error.message === 'string' ? error.message : null
  }

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [options, name, defaultValue]);

  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <select id={name} {...register(name, validationRules)} onChange={onChange ?? undefined} disabled={disabled}>
        {options.map(option => (
          <option value={option.value} key={option.value}>{option.label}</option>
        ))}
      </select>
      {errorMessage(name) && <p className="error">{errorMessage(name)}</p>}
    </div>
  )
}

export default SelectField
