import { ReportInterface } from "./ReportInterface";

export interface ImportInterface {
    id: number;
    createdAt?: Date;
    startProcessedAt?: Date | null;
    endProcessedAt?: Date | null;
    filePath?: string;
    originalFileName?: string;
    itemImportedCount: number;
    itemTreatedCount: number;
    headers: string[];
    state: ImportStateEnum;
    report: ReportInterface;
}

export enum ImportStateEnum {
    UPLOADED = "UPLOADED",
    PROCESSING = "PROCESSING",
    BAD_FIELDS = "BAD_FIELDS",
    PROCESSED = "PROCESSED",
    UNKNOW_ERROR = "UNKNOW_ERROR",
    MANUALLY_STOPPED = "MANUALLY_STOPPED"
}
export interface importRequestInterface{
    message: string;
}

export interface importRequestMissingFieldsInterface{
    message: string;
}