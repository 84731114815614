import ShopsList from "../../components/Shop/ShopsList";
import "./Shops.css";

function Shops() {
  return (
    <>
      <div className="shops-container">
        <h3>Gestion des shops</h3>
        <ShopsList />
      </div>
    </>
  );
}

export default Shops;
