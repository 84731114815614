import { ApiService } from "../services/generic/apiService";
import { useAlert } from "../context/generic/AlertContext";

interface UseEntityManagerProps<T, U> {
  apiService: ApiService<T, U>;
  callback?: () => void;
}

export const useEntityManager = <T, U>({ apiService, callback }: UseEntityManagerProps<T, U>) => {
  const { showAlert } = useAlert();

  const submitEntity = async (entity: T | null, formData: any) => {
    try {
      const newEntityData = { ...entity, ...formData };

      await apiService.push(newEntityData, newEntityData?.id);
      if(callback) callback();
      showAlert("Opération réussie.", "success");
    } catch (error) {
      showAlert("Une erreur est survenue.", "error");
    }
  };

  const deleteEntity = async (entityId: number) => {
    try {
      await apiService.remove(entityId);
      if(callback) callback();
      showAlert("Suppression confirmée.", "success");
    } catch (error) {
      showAlert("Une erreur est survenue.", "error");
    }
  };

  return {
    submitEntity,
    deleteEntity,
  };
};
