import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../generic/Modal/Modal";
import { ShopInterface, ShopProviderInterface } from "../../../interfaces/ShopInterface";
import ShopFormEdit from "../Forms/ShopFormEdit";
import { PartnerInterface } from "../../../interfaces/PartnerInterface";

interface Props {
  isOpen: boolean;
  partner?: PartnerInterface;
  onClose: () => void;
  onSubmit: (data: any) => void;
  shop?: ShopInterface | null;
}

const ShopPushModal: React.FC<Props> = ({
  isOpen,
  partner,
  onClose,
  onSubmit,
  shop,
}) => {
  const methods = useForm();

  React.useEffect(() => {
    // Reset form with new or existing shop data
    methods.reset({
      name: shop?.name ?? "",
      address: shop?.address ?? "",
      phoneNumber: shop?.phoneNumber ?? "",
      shopProviders: shop?.shopProviders ?? [],
      comment: shop?.comment ?? "",
      partnerId: shop?.partner.id ?? partner?.id ?? ""
    });
  }, [shop, partner, methods.reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={shop ? "Modifier un shop" : "Ajouter un shop"}
      size="large"
    >
      <ShopFormEdit partner={partner} shop={shop} onSubmit={(formData) => {
        // Prepare data for submission, including all providers
        onSubmit({
          ...formData,
          shopProviders: formData.shopProviders.map((provider: ShopProviderInterface) => ({
            provider: provider.provider,
            customName: provider.customName
          }))
        });
      }} />
    </Modal>
  );
};

export default ShopPushModal;
