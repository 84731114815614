import React, { useCallback, useState } from "react";
import OrdersTable from "./OrderTable";
import OrderDeleteConfirmationModal from "./Modals/OrderDeleteConfirmationModal";
import { OrderInterface } from "../../interfaces/OrderInterface";
import { useEntityManager } from "../../hooks/useEntityManager";
import { ApiService } from "../../services/generic/apiService";
import OrderShowModal from "./Modals/OrderShowModal";

interface OrderListProps {
}

const OrdersList: React.FC<OrderListProps> = ({ }) => {
  const apiService = new ApiService<OrderInterface, OrderInterface>('/orders');
  // State hooks
  const [
    isOrderDeleteConfirmationModalOpen,
    setIsOrderDeleteConfirmationModalOpen,
  ] = useState(false);
  const [
    isOrderShowModalOpen,
    setIsOrderShowModalOpen,
  ] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState<OrderInterface | null>(
    null
  );

  const fetchPaginatedOrders = async (page: number, limit: number, query: string) => {
    const response = await apiService.getResponse(undefined, { page, limit, query });
    return {
      data: response.data,
      total: response.headers["x-total-count"] ?? 0,
    };
  };

  const reloadOrders = () => {  };
  const { deleteEntity } = useEntityManager<OrderInterface, OrderInterface>({ apiService: apiService, callback: reloadOrders });

  // Callbacks for Order actions
  const openDeleteConfirmationModal = useCallback((Order: OrderInterface) => {
    setSelectedOrder(Order);
    setIsOrderDeleteConfirmationModalOpen(true);
  }, []);

  const closeDeleteConfirmationModal = useCallback(() => {
    setIsOrderDeleteConfirmationModalOpen(false);
    setSelectedOrder(null);
  }, []);
  const openOrderShowModal = useCallback((Order: OrderInterface) => {
    setSelectedOrder(Order);
    setIsOrderShowModalOpen(true);
  }, []);

  const closeOrderShowModal = useCallback(() => {
    setIsOrderShowModalOpen(false);
    setSelectedOrder(null);
  }, []);

  //Handle Forms

  const handleDelete = useCallback(() => {
    if (selectedOrder) {
      deleteEntity(selectedOrder.id);
    }
    setIsOrderDeleteConfirmationModalOpen(false);
  }, [deleteEntity, selectedOrder]);

  return (
    <>
      <OrdersTable
        fetchPaginatedOrders={fetchPaginatedOrders}
        onDelete={openDeleteConfirmationModal}
        onShow={openOrderShowModal}
        pageSize={10} // Définissez ici le nombre d'éléments par page
      />
      <OrderDeleteConfirmationModal
        isOpen={isOrderDeleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onDelete={handleDelete}
        order={selectedOrder}
      />
      <OrderShowModal
        isOpen={isOrderShowModalOpen}
        onClose={closeOrderShowModal}
        order={selectedOrder}
      />
    </>
  );
};

export default OrdersList;
