import React, { FC, useState, ReactNode, MouseEvent } from 'react';
import './tooltip.css';  // Ensure your CSS is correctly imported

interface TooltipProps {
  children: ReactNode;
  content: ReactNode;
}

const Tooltip: FC<TooltipProps> = ({ children, content }) => {
  const [visible, setVisible] = useState(false);
  const [coords, setCoords] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (event: MouseEvent) => {
    setVisible(true);
    const { clientX, clientY } = event;
    setCoords({
      x: clientX,
      y: clientY
    });
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} className="tooltip-container">
      {children}
      {visible && (
        <div className="tooltip-box" style={{
          left: `${coords.x}px`,
          top: `${coords.y + 20}px`  // 20px is added for a little offset from the cursor
        }}>
          {content}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
