import AutomationSummaryComponent from "../../../components/Automations/Summary/Summary";
import "./Summary.css";

function Summary() {
  return (
    <>
      <div className="shops-container">
        <h3>Automatisation - Recaps</h3>
        <AutomationSummaryComponent />
      </div>
    </>
  );
}

export default Summary;
