import { useMenu } from "../../context/MenuContext";
import { useAuth } from "../../context/generic/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import "./navbar.css";
import { useState } from "react";

const Navbar = () => {
  const { toggleNavbarMenu, toggleSidebar, isNavbarMenuOpen } = useMenu();
  const { isAuthenticated, logoutUser, user } = useAuth();
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleLogout = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    logoutUser();
    navigate("/login");
  };

  return (
    <>
      <nav className="">
        <div className="container">
          <button id="sideMenuButton" className="" onClick={toggleSidebar}>
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16m-16 6h7"></path>
            </svg>
          </button>
          <a href="#" className="navItem home">
            Savour
          </a>
          <button id="menuButton" onClick={toggleNavbarMenu}>
            <svg
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
          {isAuthenticated && (
            <div className="navItems">
              <div className="navItem dropdown" onClick={() => setIsDropdownOpen(!isDropdownOpen)}>
                {user?.login}
                <div className={`dropdown-content ${isDropdownOpen ? "show" : ""}`}>
                  <Link to="/change-password" className="dropdown-item">Changer mon mot de passe</Link>
                  <a href="/logout" className="dropdown-item" onClick={handleLogout}>
                    Déconnexion
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
        <div id="mobileNavItems" className={isNavbarMenuOpen ? "" : "hidden"}>
          {isAuthenticated && (
            <>
              <a href="/logout" className="navItem" onClick={handleLogout}>
                Déconnexion
              </a>
            </>
          )}
        </div>
      </nav>
    </>
  );
};

export default Navbar;
