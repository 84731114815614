import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface DateRangeFormProps {
  startDate: Date | null;
  endDate: Date | null;
  onStartDateChange: (date: Date | null) => void;
  onEndDateChange: (date: Date | null) => void;
  startDateLabel?: string;
  endDateLabel?: string;
}

const DateRangeForm: React.FC<DateRangeFormProps> = ({
  startDate,
  endDate,
  onStartDateChange,
  onEndDateChange,
  startDateLabel,
  endDateLabel,
}) => {
  return (
    <div className="form-grid">
      <div className="form-group">
        <label htmlFor="startDate">{startDateLabel ?? "Start Date"}</label>
        <DatePicker
          selected={startDate}
          onChange={onStartDateChange}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          dateFormat="dd/MM/yyyy"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label htmlFor="endDate">{endDateLabel ?? "End Date"}</label>
        <DatePicker
          selected={endDate}
          onChange={onEndDateChange}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          dateFormat="dd/MM/yyyy"
          className="form-control"
        />
      </div>
    </div>
  );
};

export default DateRangeForm;
