import React from "react";
import { OrderInterface } from "../../interfaces/OrderInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import PaginatedTable from "../generic/Table/PaginatedTable";
import { useTranslation } from "react-i18next";

interface OrdersTableProps {
  fetchPaginatedOrders: (page: number, limit: number, query: string) => Promise<{ data: OrderInterface[]; total: any }>;
  onShow: (order: OrderInterface) => void;
  onDelete: (order: OrderInterface) => void;
  pageSize: number;
}

const OrdersTable: React.FC<OrdersTableProps> = ({
  fetchPaginatedOrders,
  onDelete,
  onShow,
  pageSize
}) => {
  const { t: tProviders } = useTranslation('providers');

  const headers = [
    "Partenaire",
    "Fournisseur",
    "Shop",
    "Identifiant",
    "Montant",
    "Commentaire",
    "Problème",
    "Date",
    "Action",
  ];

  const formatData = (importEntities: OrderInterface[]) => importEntities.map(order => ({
    "Partenaire": order?.shopProvider.shop?.partner.name || "",
    "Fournisseur": tProviders(order?.shopProvider.provider || ""),
    "Shop": order?.shopProvider.customName || "",
    "Identifiant": order?.orderId || "",
    "Montant": order?.averageOrderAmount || "",
    "Commentaire": order?.reviews?.length || "",
    "Problème": order?.orderIssues?.length || "",
    "Date": order?.orderDate ? format(new Date(order.orderDate), 'dd/MM/yyyy HH:mm:ss', { locale: fr }) : "",
    "Action": order.id ? (
      <>
      <button onClick={() => onShow(order)}>
        <FontAwesomeIcon icon={faEye} />
      </button>
        <button className="btn-delete" onClick={() => onDelete(order)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    ) : null,
  }));

  return (
    <PaginatedTable
      headers={headers}
      columnWidths={["auto", "11em", "auto", "12em", "auto", "10em"]}
      headerAlignments={["left", "left", "left", "left", "left", "center"]}
      contentAlignments={["left", "left", "left", "left", "left", "center"]}
      fetchData={(page, limit, query) => fetchPaginatedOrders(page, limit, query).then(response => ({ data: formatData(response.data), total: response.total }))}
      initialPageSize={pageSize}
    />
  );
};

export default OrdersTable;
