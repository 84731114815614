import AutomationAuditComponent from "../../../components/Automations/Audit/Audit";
import "./Audit.css";

function Audit() {
  return (
    <>
      <div className="shops-container">
        <h3>Automatisation - Audit</h3>
        <AutomationAuditComponent />
      </div>
    </>
  );
}

export default Audit;
