import ReportsList from "../../components/Report/ReportsList";
import "./Reports.css";
import { useTranslation } from 'react-i18next';
import '../../i18n';

function Reports() {
  const { t } = useTranslation();
  return (
    <>
      <div className="Reports-container">
        <h3>{t('report.title')}</h3>
        <ReportsList />
      </div>
    </>
  );
}

export default Reports;
