import React, { useState } from "react"
import LoginLayout from "../../components/Layout/LoginLayout"
import { useNavigate } from "react-router-dom"
import { ApiUserService } from "../../services/apiUserService"
import './login.css'
import { useAlert } from "../../context/generic/AlertContext"
import { useAuth } from "../../context/generic/AuthContext"
import Modal from "../../components/generic/Modal/Modal"

const Login = () => {
  const apiUserService = new ApiUserService();
  const { loginUser } = useAuth();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    try {
      const data = await apiUserService.login(login, password)
      loginUser(data.token)
      navigate("/dashboard");
    } catch (error) {
      showAlert("Identifiants invalides", "error")
    }
  }

  const handleForgotPasswordSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    try {
      await apiUserService.forgotPassword(email);
      setIsForgotPasswordModalOpen(false);
      showAlert("Email de réinitialisation envoyé.", "success");
    } catch (error) {
      showAlert("Une erreur s'est produite.", "error");
    }
  };

  return (
    <>
      <form className="login-form" onSubmit={handleSubmit}>
        <div className="form-element-container">
          <label htmlFor="login">Nom d'utilisateur</label>
          <input
            id="login"
            type="text"
            placeholder="Nom d'utilisateur"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            required
          />
        </div>
        <div className="form-element-container">
          <label htmlFor="password">Mot de passe</label>
          <input
            id="password"
            type="password"
            placeholder="******************"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-submit-container">
          <button type="submit">Se connecter</button>
        </div>
        <div className="form-submit-container">
          <a href="#" onClick={() => setIsForgotPasswordModalOpen(true)}>Mot de passe oublié</a>
        </div>
      </form>


      <Modal
        className="reset-password-modal"
        isOpen={isForgotPasswordModalOpen}
        onClose={() => setIsForgotPasswordModalOpen(false)}
        title="Réinitialisation du mot de passe"
      >
        <form className="reset-password-form" onSubmit={handleForgotPasswordSubmit}>
          <div className="form-element-container">
            <label htmlFor="email">Adresse e-mail</label>
            <input
              id="email"
              type="email"
              placeholder="Adresse e-mail"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-submit-container">
            <button type="submit">Envoyer</button>
          </div>
        </form>
      </Modal>
    </>
  )
}

export default Login
