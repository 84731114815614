import React from 'react';
import Modal from '../../generic/Modal/Modal';
import { ShopPerformanceInterface } from '../../../interfaces/ShopPerformanceInterface';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  shopPerformance: ShopPerformanceInterface | null;
}

const ShopPerformanceDeleteConfirmationModal: React.FC<Props> = ({ isOpen, onClose, onDelete, shopPerformance }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirmez vous cette action ?" size="large">
      <div>
        Confirmez-vous la suppression des performances pour le shop "{shopPerformance?.shopProvider.customName}" pour la période du {shopPerformance?.startDate ? format(shopPerformance.startDate, 'dd/MM/yyyy', { locale: fr }) : ""} au {shopPerformance?.endDate ? format(shopPerformance.startDate, 'dd/MM/yyyy', { locale: fr }) : ""}?
        <div className="buttons-container">
          <button className="btn-delete" onClick={onDelete}>
            Confirmer
          </button>
          <button onClick={onClose}>Annuler</button>
        </div>
      </div>
    </Modal>
  );
};

export default ShopPerformanceDeleteConfirmationModal;
