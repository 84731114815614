import React, { useEffect, useState } from "react";
import ShopsTable from "./ShopsTable";
import { ShopInterface } from "../../interfaces/ShopInterface";
import { ApiService } from "../../services/generic/apiService";
import { PartnerInterface } from "../../interfaces/PartnerInterface";
import { ApiAutomationService } from "../../services/apiAutomationService";

interface ShopListProps {
  partner?: PartnerInterface;
}

const ShopsList: React.FC<ShopListProps> = ({ partner }) => {
  const apiShopsService = new ApiService<ShopInterface, ShopInterface>('/shops');
  const apiAutomationsCodesService = new ApiService<string, string>('/automations/codes');
  const apiAutomationService = new ApiAutomationService();
  // State hooks
  const [automations, setAutomations] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchPaginatedShops = async (page: number, limit: number, query: string) => {
    const params = partner ? { partnerId: partner.id.toString() } : {};
    const response = await apiShopsService.getResponse(undefined, { page, limit, query, ...params });
    return {
      data: response.data,
      total: response.headers["x-total-count"] ?? 0,
    };
  };

  const getAutomations = async () => {
    setAutomations(await apiAutomationsCodesService.get());
  };

  useEffect(() => {
    const fetchData = async () => {
      await getAutomations();
      setLoading(false);
    };
    fetchData();
  }, []);

  const handletoggleAutomation = (shop: ShopInterface, automationCode: string, isChecked: boolean) => {
    apiAutomationService.toggle(shop, automationCode, isChecked);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <ShopsTable
        fetchPaginatedShops={fetchPaginatedShops}
        automations={automations}
        onToggle={handletoggleAutomation}
        pageSize={10} // Définissez ici le nombre d'éléments par page
      />
    </>
  );
};

export default ShopsList;
