import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import InputField from "../../generic/Form/Fields/Input";
import { FieldMappingsInterface, ReportInterface, FieldMappingDetailsInterface } from "../../../interfaces/ReportInterface";
import { ApiService } from "../../../services/generic/apiService";
import { useTranslation } from 'react-i18next';
import '../../../i18n';
import { ImportInterface } from "../../../interfaces/ImportInterface";
import SelectField, { Option } from "../../generic/Form/Fields/Select";

interface Props {
  importEntity?: ImportInterface | null;
  onSubmit: (data: any) => void;
}

interface CheckboxStates {
  [key: string]: boolean | undefined;
}

interface FieldVisibilityStates {
  [entity: string]: {
    [field: string]: boolean;
  };
}

const ReportFormEditMapping: React.FC<Props> = ({ onSubmit, importEntity }) => {
  const { t: tReport } = useTranslation('report');
  const apiService = new ApiService<FieldMappingsInterface, FieldMappingsInterface>('/reports/fields');
  const [fieldMappingsData, setFieldMappingsData] = useState<FieldMappingsInterface>({});
  const [initialFieldMapping, setInitialFieldMapping] = useState<FieldMappingsInterface>({});
  const [checkedEntities, setCheckedEntities] = useState<{ [key: string]: boolean | undefined }>({});
  const [headersOptions, setHeadersOptions] = useState<Option[]>([]);
  const [fieldVisibility, setFieldVisibility] = useState<{ [entity: string]: { [field: string]: boolean | undefined } }>({});

  const methods = useForm<ReportInterface>({
    defaultValues: {
      provider: importEntity?.report?.provider ?? "",
      label: importEntity?.report?.label ?? "",
      fieldMappings: importEntity?.report?.fieldMappings ?? initialFieldMapping
    }
  });
  const { watch } = methods;

  useEffect(() => {
    const fetchFieldMappings = async () => {
      const data: FieldMappingsInterface = await apiService.getObject();
      setFieldMappingsData(data);
      const initialCheckboxState: CheckboxStates = {};
      const initialVisibilityState: FieldVisibilityStates = {};

      Object.keys(data).forEach(entity => {
        initialCheckboxState[entity] = (importEntity?.report?.fieldMappings && importEntity.report.fieldMappings[entity]) ? true : false;
        initialVisibilityState[entity] = {};
        if (importEntity?.report && importEntity.report.fieldMappings[entity] && importEntity.report.fieldMappings[entity].fields) {
          Object.entries(importEntity.report.fieldMappings[entity].fields).map(([field, value]) => {
            initialVisibilityState[entity][field] = value?.name ? true : false;
          });
        }
      });

      setCheckedEntities(initialCheckboxState);
      setFieldVisibility(initialVisibilityState);

      setInitialFieldMapping(
        Object.keys(data).reduce((acc: FieldMappingsInterface, key) => {
          let value = data[key];
          const fields = Object.entries(value.fields).reduce((fieldsAcc: FieldMappingDetailsInterface, [fieldName, fieldDetails]) => {
            fieldsAcc[fieldName] = {
              name: '',
              type: fieldDetails.type,
              required: fieldDetails.required
            };
            return fieldsAcc;
          }, {});

          acc[key] = {
            fields,
          };
          if (value.keyIdentifier && value.keyIdentifier.field && value.keyIdentifier.entity) {
            acc[key].keyIdentifier = {
              name: '',
              field: value.keyIdentifier.field,
              entity: value.keyIdentifier.entity
            }
          }
          return acc;
        }, {})
      );
    };


    fetchFieldMappings();
  }, []);

  React.useEffect(() => {
    methods.reset({
      provider: importEntity?.report?.provider ?? "",
      label: importEntity?.report?.label ?? "",
      fieldMappings: importEntity?.report?.fieldMappings ?? initialFieldMapping
    });
    if (importEntity?.headers) {
      setHeadersOptions(importEntity.headers.reduce((acc: Option[], value: string) => {
        acc.push({ value: value, label: value });
        return acc;
      }, []));
    }

  }, [importEntity, methods.reset, initialFieldMapping]);

  const handleFormSubmit = methods.handleSubmit((formData) => {
    formData.fieldMappings = Object.keys(formData.fieldMappings).reduce((acc: FieldMappingsInterface, key) => {
      if (checkedEntities[key] === true) {
        acc[key] = formData.fieldMappings[key];
      }
      return acc;
    }, {})
    onSubmit(formData);  // Appel à onSubmit avec les données filtrées
  });

  const isFieldValueInHeaders = (fieldName: string, entityName: string) => {
    const fieldValue = watch(`fieldMappings.${entityName}.${fieldName}`);
    return importEntity?.headers && !importEntity.headers.includes(fieldValue);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleFormSubmit} className="form">
        {Object.entries(fieldMappingsData).filter(([entityName]) => checkedEntities[entityName] ?? false).map(([entityName, descriptor]) => (
          <fieldset key={entityName}>
            <legend>{tReport(`report.entities.${entityName}`)}</legend>
            {
              (
                isFieldValueInHeaders('keyIdentifier.name', entityName)
              ) && (
                <div className="form-group-flex">

                  <>
                    <SelectField
                      key={`fieldMappings.${entityName}.keyIdentifier.name`}
                      name={`fieldMappings.${entityName}.keyIdentifier.name`}
                      label={tReport(`report.fields.${entityName}.keyIdentifier`)}
                      options={headersOptions}
                      validationRules={{ required: "Ce champ est requis" }}
                    />
                  </>
                </div>
              )
            }
            {Object.entries(descriptor.fields)
              .filter(([fieldName]) => (!descriptor.keyIdentifier || fieldName !== descriptor.keyIdentifier.field))
              .map(([fieldName, field]) => (
                <>

                  <div className="form-group-flex">
                    {(
                      checkedEntities[entityName] === true
                      && (field.required === true || (fieldVisibility[entityName] && fieldVisibility[entityName][fieldName]))
                      && isFieldValueInHeaders(`fields.${fieldName}.name`, entityName)
                    ) && (
                        <>
                          <SelectField
                            key={`input-${entityName}-${fieldName}`}
                            name={`fieldMappings.${entityName}.fields.${fieldName}.name`}
                            label={tReport(`report.fields.${entityName}.${fieldName}`)}
                            options={headersOptions}
                            validationRules={{ required: "Ce champ est requis" }}
                          />
                        </>
                      )}
                  </div>
                </>
              ))}
          </fieldset>
        ))}

        <div className="buttons-container">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    </FormProvider >
  );
};

export default ReportFormEditMapping;
