import React, { useCallback } from "react";
import { ApiUserService } from "../../../services/apiUserService";
import { RegisterOptions } from "react-hook-form";
import SelectFetcher from "../../generic/Form/Fields/SelectFetcher";

interface AccountManagerSelectProps {
  name: string;
  label: string;
  defaultValue?: string;
  validationRules?: RegisterOptions;
  emptyLabel?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface AccountManagerOptionInterface {
    value: string;
    label: string;
}

const AccountManagerSelect: React.FC<AccountManagerSelectProps> = ({
  name,
  label,
  defaultValue,
  validationRules,
  emptyLabel,
  onChange,
}) => {
  const apiUserService = new ApiUserService();
  const loadManagers = useCallback(async () => {
    const users = await apiUserService.get(undefined, {"role": "ROLE_ACCOUNT_MANAGER"});
    let options: AccountManagerOptionInterface[] = [];

    users.forEach(user => {
      options.push({ value: user.id.toString(), label: user.login });
    })
    return options;
  }, []);

  return (
    <SelectFetcher
      name={name}
      label={label}
      loadOptionsFunction={loadManagers}
      defaultValue={defaultValue}
      validationRules={validationRules}
      emptyChoice={emptyLabel ? {value:"", label:emptyLabel ?? ""} : undefined}
      onChange={onChange}
    />
  );
};

export default AccountManagerSelect;
