import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../generic/Modal/Modal";
import { ReportInterface } from "../../../interfaces/ReportInterface";
import ReportFormEdit from "../Forms/ReportFormEdit";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  report?: ReportInterface | null;
}

const ReportPushModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  report,
}) => {
  const methods = useForm();

  React.useEffect(() => {
    // Reset form with new or existing report data
    methods.reset({
      provider: report?.provider ?? "",
      label: report?.label ?? "",
      // Assuming additional properties might need to be reset, adapt as necessary.
      fieldMappings: report?.fieldMappings ?? {},
    });
  }, [report, methods.reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={report ? "Modifier un rapport" : "Ajouter un rapport"}
      size="xlarge"
    >
      <ReportFormEdit report={report} onSubmit={onSubmit} />
    </Modal>
  );
};

export default ReportPushModal;
