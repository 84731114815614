import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import UsersTable from "./UsersTable";
import UserPushModal from "./Modals/UserPushModal";
import UserDeleteConfirmationModal from "./Modals/UserDeleteConfirmationModal";
import { UserInterface } from "../../../interfaces/UserInterface";
import { useEntityManager } from "../../../hooks/useEntityManager";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiService } from "../../../services/generic/apiService";

const UsersList: React.FC = () => {
  const apiService = new ApiService<UserInterface, UserInterface>('/users');
  // State hooks
  const [isUserPushModalOpen, setIsUserPushModalOpen] = useState(false);
  const [users, setUsers] = useState<UserInterface[]>([]);
  const [
    isUserDeleteConfirmationModalOpen,
    setIsUserDeleteConfirmationModalOpen,
  ] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserInterface | null>(
    null
  );

  

  const getUsers = async () => {
    setUsers(await apiService.get());
  }

  useEffect(() => {
    getUsers();
  }, []);
  
  const reloadUsers = () => {getUsers()};
  const { submitEntity, deleteEntity } = useEntityManager<UserInterface, UserInterface>({apiService: apiService, callback: reloadUsers});

  // Callbacks for user actions
  const openUserPushModal = useCallback((user?: UserInterface) => {
    setSelectedUser(user || null);
    setIsUserPushModalOpen(true);
  }, []);

  const closeUserPushModal = useCallback(() => {
    setIsUserPushModalOpen(false);
    setSelectedUser(null);
  }, []);

  const openDeleteConfirmationModal = useCallback((user: UserInterface) => {
    setSelectedUser(user);
    setIsUserDeleteConfirmationModalOpen(true);
  }, []);

  const closeDeleteConfirmationModal = useCallback(() => {
    setIsUserDeleteConfirmationModalOpen(false);
    setSelectedUser(null);
  }, []);

  //Handle Forms
  const handleSubmit = useCallback(
    (formData: any) => {
      formData.redirectUrl = `${process.env.REACT_APP_BASE_URL}create-password`;
      if (selectedUser) {
        submitEntity(selectedUser, formData);
      } else {
        submitEntity(null, formData);
      }
      closeUserPushModal();
    },
    [submitEntity, selectedUser]
  );

  const handleDelete = useCallback(() => {
    if (selectedUser) {
      deleteEntity(selectedUser.id);
    }
    setIsUserDeleteConfirmationModalOpen(false);
  }, [deleteEntity, selectedUser]);

  return (
    <>
      <button onClick={() => openUserPushModal()}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
      <UsersTable
        users={users}
        onEdit={openUserPushModal}
        onDelete={openDeleteConfirmationModal}
      />
      <UserPushModal
        isOpen={isUserPushModalOpen}
        onClose={closeUserPushModal}
        onSubmit={handleSubmit}
        user={selectedUser}
      />
      <UserDeleteConfirmationModal
        isOpen={isUserDeleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onDelete={handleDelete}
        user={selectedUser}
      />
    </>
  );
};

export default UsersList;
