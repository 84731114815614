import React, { forwardRef } from "react";
import PaginatedTable from "../generic/Table/PaginatedTable";
import { EmailConfigurationInterface } from "../../interfaces/EmailConfigurationInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

interface EmailConfigurationsTableProps {
  fetchPaginatedEmailConfigurations: (page: number, limit: number, query: string) => Promise<{ data: EmailConfigurationInterface[]; total: any }>;
  onEdit: (emailConfiguration: EmailConfigurationInterface) => void;
  pageSize: number;
}

const EmailConfigurationsTable = forwardRef<any, EmailConfigurationsTableProps>(({
  fetchPaginatedEmailConfigurations,
  onEdit,
  pageSize
}, ref) => {
  const headers = [
    "Partenaire",
    "Automatisation",
    "Sujet",
    "CC",
    "Action",
  ];

  const formatData = (emailConfigurations: EmailConfigurationInterface[]) => emailConfigurations.map(emailConfiguration => ({
    "Partenaire": emailConfiguration.partner?.name || "",
    "Automatisation": emailConfiguration.automation || "",
    "Sujet": emailConfiguration.subject || "",
    "CC": emailConfiguration.cc || "",
    "Action": emailConfiguration.id ? (
      <>
        <button onClick={() => onEdit(emailConfiguration)}>
          <FontAwesomeIcon icon={faEdit} />
        </button>
      </>
    ) : null,
  }));

  return (
    <PaginatedTable
      ref={ref}
      headers={headers}
      columnWidths={["auto", "auto", "auto", "auto", "12em"]}
      headerAlignments={["left", "left", "left", "left", "center"]}
      contentAlignments={["left", "left", "left", "left", "center"]}
      fetchData={(page, limit, query) => fetchPaginatedEmailConfigurations(page, limit, query).then(response => ({ data: formatData(response.data), total: response.total }))}
      initialPageSize={pageSize}
    />
  );
});

export default EmailConfigurationsTable;
