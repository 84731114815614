import { ApiService, request } from "./generic/apiService";
import { UserInterface } from "../interfaces/UserInterface";
import { AxiosResponse } from "axios";

export class ApiUserService extends ApiService<UserInterface, UserInterface> {
  constructor() {
    super(`/users`);
  }

  login = async (email: string, password: string) => {
    try {
      const response = await request({
        url: `/login_check`,
        method: 'POST',
        data: {
          email,
          password,
        },
      })
      return response.data
    } catch (error) {
      throw error
    }
  }
  
  forgotPassword = async (email: string): Promise<void> => {
    const redirectUrl = `${process.env.REACT_APP_BASE_URL}reset-password`;
    try {
      await request({
        url: `${this.basePath}/forgot-password`,
        method: 'POST',
        data: { 
          email,
          redirectUrl
        },
      });
    } catch (error) {
      throw error;
    }
  };
  
  resetPassword = async (token: string, password: string): Promise<void> => {
    try {
      await request({
        url: `${this.basePath}/reset-password/` + token,
        method: 'POST',
        data: { password },
      });
    } catch (error) {
      throw error;
    }
  };
  
  changePassword = async (userId: number, password: string): Promise<void> => {
    try {
      await request({
        url: `${this.basePath}/${userId}`,
        method: 'PUT',
        data: { password },
      });
    } catch (error) {
      throw error;
    }
  };
  
  fetchUserInfo = async (): Promise<UserInterface> => {
    try {
      
      const response: AxiosResponse<UserInterface> = await request({
        url: `${this.basePath}/me`,
        method: 'GET',
      });
      return response.data;
    } catch (error) {
      throw new Error("Failed to fetch user info");
    }
  };
}