import React, { useEffect, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import PaginatedTable from "../generic/Table/PaginatedTable";
import CheckboxField from "../generic/Form/Fields/Checkbox";
import { ShopInterface } from "../../interfaces/ShopInterface";
import { AutomationInterface } from "../../interfaces/AutomationInterface";
import { useTranslation } from "react-i18next";

interface ShopsTableProps {
  fetchPaginatedShops: (page: number, limit: number, query: string) => Promise<{ data: ShopInterface[]; total: any }>;
  automations: string[];
  onToggle: (shop: ShopInterface, automationCode: string, isChecked: boolean) => void;
  pageSize: number;
}

const ShopsTable: React.FC<ShopsTableProps> = ({
  fetchPaginatedShops,
  automations,
  onToggle,
  pageSize,
}) => {
  const [translatedAutomations, setTranslatedAutomations] = useState<string[]>([]);
  const { t: tAutomations } = useTranslation('automations');
  const methods = useForm();

  useEffect(() => {
    setTranslatedAutomations(automations.map(automation => tAutomations(`codes.${automation}`)));
  }, [automations]);

  const headers = [
    "Partenaire",
    "Manager",
    "Nom",
    ...translatedAutomations,
  ];

  const formatData = (shops: ShopInterface[]) => {
    return shops.map((shop, shopIndex) => {
      const automationsData = automations.reduce((acc, automation) => {
        let hasAutomation = shop.automations.some(
          (auto: AutomationInterface) => auto.code === automation
        );
        acc[tAutomations(`codes.${automation}`)] = (
          <CheckboxField
            key={`checkbox-${shop.id}-${automation}`}
            name={`shops[${shop.id}].automations[${automation}]`}
            value={automation}
            checked={hasAutomation}
            disabled={false}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => onToggle(shop, automation, event.target.checked)}
          />
        );
        return acc;
      }, {} as { [key: string]: React.ReactNode });

      return {
        Partenaire: shop.partner?.name || "",
        Manager: shop.partner?.manager?.email || "",
        Nom: shop.name || "",
        ...automationsData
      };
    });
  };

  const fetchData = async (page: number, limit: number, query: string) => {
    const response = await fetchPaginatedShops(page, limit, query);
    if (automations.length > 0) {
      methods.reset();
      const formattedData = formatData(response.data);
      return { data: formattedData, total: response.total };
    }
    return { data: [], total: response.total };
  };

  return (
    <FormProvider {...methods}>
      <form>
        <PaginatedTable
          headers={headers}
          columnWidths={Array(headers.length).fill("auto")}
          headerAlignments={Array(headers.length).fill("left")}
          contentAlignments={Array(headers.length).fill("left")}
          fetchData={fetchData}
          initialPageSize={pageSize}
        />
      </form>
    </FormProvider>
  );
};

export default ShopsTable;
