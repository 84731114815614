import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import InputField from "../../../generic/Form/Fields/Input";
import { UserInterface } from "../../../../interfaces/UserInterface";
import SelectField, { Option } from "../../../generic/Form/Fields/Select";
import { useUserManager } from "../../../../hooks/useUserManager";
import { MultiValue, SingleValue } from "react-select";
import MultiSelectField from "../../../generic/Form/Fields/MultiSelect";

interface Props {
  user?: UserInterface | null;
  onSubmit: (data: any) => void;
}

const UserFormEdit: React.FC<Props> = ({ onSubmit, user }) => {
  const methods = useForm();
  const { getHigestCurrentRole } = useUserManager({ reloadUsers: undefined });

  React.useEffect(() => {
    methods.reset({
      email: !user ? "" : user.email,
      emailSimulation: !user ? "" : user.emailSimulation,
      login: !user ? "" : user.login,
      roles: !user ? [] : user.roles
    });
  }, [user, methods]);

  const handleRoleChange = (selectedOptions: MultiValue<Option> | SingleValue<Option>) => {
    const newRoles = (selectedOptions as MultiValue<Option>).map(option => option.value);
    methods.setValue('roles', newRoles);
  };

  const options = [
    { value: "ROLE_ADMIN", label: "Administrateur" },
    { value: "ROLE_ACCOUNT_MANAGER", label: "Account Manager" },
    { value: "ROLE_SALES", label: "Commercial" }
  ];

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField
          name="email"
          label="Email"
          type="email"
          validationRules={{ required: "Ce champ est requis" }}
        />
        <InputField
          name="emailSimulation"
          label="Email Simulation"
          type="email"
          validationRules={{ required: "Ce champ est requis" }}
        />
        <InputField
          name="login"
          label="login"
          type="text"
          validationRules={{ required: "Ce champ est requis" }}
        />

        <MultiSelectField
          name="role"
          label="Rôle"
          options={options}
          defaultValue={user?.roles ?? ["ROLE_ADMIN"]}
          validationRules={{ required: "Ce champ est requis" }}
          onChange={handleRoleChange}
        />
        <div className="buttons-container">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    </FormProvider>
  );
};

export default UserFormEdit;
