import React from 'react';
import Modal from '../../generic/Modal/Modal';
import { OrderInterface } from '../../../interfaces/OrderInterface';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import './OrderShowModal.css';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  order: OrderInterface | null;
}

const OrderShowModal: React.FC<Props> = ({ isOpen, onClose, order }) => {
  if (!order) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Détails de la commande" size="large">
      <div className="order-details">
        <h2>Commande #{order.orderId}</h2>
        <p><strong>Status:</strong> {order.status}</p>
        <p><strong>Date:</strong> {order?.orderDate ? format(order.orderDate, 'dd/MM/yyyy HH:mm:ss', { locale: fr }) : ""}</p>
        <p><strong>Type de commande:</strong> {order.orderType}</p>
        <p><strong>Nombre d'articles:</strong> {order?.menuItemCount ?? ""}</p>
        <p><strong>Montant:</strong> {order.averageOrderAmount.toFixed(2)}€</p>

        {order.reviews && order.reviews.length > 0 ? (
          <div className="order-details-section">
            <h3>Commentaires</h3>
            {order.reviews.map(review => (
              <div key={review.id} className="order-details-section-item">
                <p><strong>Date:</strong> {review?.date ? format(review?.date, 'dd/MM/yyyy HH:mm:ss', { locale: fr }) : ""}</p>
                <p><strong>Note:</strong> {review.rating}/5</p>
                <p><strong>Type de note:</strong> {review.ratingType}</p>
                <p><strong>Commentaire:</strong> {review.comment}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Aucun commentaire</p>
        )}

        {order.orderIssues && order.orderIssues.length > 0 ? (
          <div className="order-details-section">
            <h3>Problèmes</h3>
            {order.orderIssues.map(orderIssue => (
              <div key={orderIssue.id} className="order-details-section-item">
                <p><strong>Date de remboursement:</strong> {orderIssue?.refundDateTime ? format(orderIssue?.refundDateTime, 'dd/MM/yyyy HH:mm:ss', { locale: fr }) : ""}</p>
                <p><strong>Nom du plat:</strong> {orderIssue.dishName}</p>
                <p><strong>Problème sur la commande:</strong> {orderIssue.orderProblem}</p>
                <p><strong>Problème sur le plat:</strong> {orderIssue.dishProblem}</p>
                <p><strong>Montant du remboursement:</strong> {orderIssue.dishRefundAmount}</p>
                <p><strong>Montant du plat:</strong> {orderIssue.incorrectDishPrice}</p>
                <p><strong>Personnalisation du plat:</strong> {orderIssue.dishCustomization}</p>
                <p><strong>Commentaire:</strong> {orderIssue.dishComment}</p>
              </div>
            ))}
          </div>
        ) : (
          <p>Aucun problème</p>
        )}
      </div>
    </Modal>
  );
};

export default OrderShowModal;
