import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../generic/Modal/Modal";
import ReportFormEditMapping from "../Forms/ReportFormEditMapping";
import { ImportInterface } from "../../../interfaces/ImportInterface";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  importEntity?: ImportInterface | null;
}

const ReportMapModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  importEntity,
}) => {
  const methods = useForm();

  React.useEffect(() => {
    // Reset form with new or existing report data
    methods.reset({
      provider: importEntity?.report?.provider ?? "",
      label: importEntity?.report?.label ?? "",
      // Assuming additional properties might need to be reset, adapt as necessary.
      fieldMappings: importEntity?.report?.fieldMappings ?? {},
    });
  }, [importEntity, methods.reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={"Mapping des en-têtes"}
      size="large"
    >
      <ReportFormEditMapping importEntity={importEntity} onSubmit={onSubmit} />
    </Modal>
  );
};

export default ReportMapModal;
