import React from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import InputField from "../../generic/Form/Fields/Input";
import ProviderSelect from "./ProviderSelect";
import PartnerSelect from "../../Partner/Forms/PartnerSelect";
import { ShopInterface } from "../../../interfaces/ShopInterface";
import { PartnerInterface } from "../../../interfaces/PartnerInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

interface Props {
  shop?: ShopInterface | null;
  onSubmit: (data: any) => void;
  partner?: PartnerInterface;
}

interface ShopFormValues {
  name: string;
  address: string;
  phoneNumber: string;
  shopProviders: { provider: string | undefined; customName: string | undefined }[];
  comment: string;
  partnerId: string;
}

const ShopFormEdit: React.FC<Props> = ({ onSubmit, shop, partner }) => {
  const methods = useForm<ShopFormValues>({
    defaultValues: {
      name: "",
      address: "",
      phoneNumber: "",
      shopProviders: [{ provider: undefined, customName: undefined }],
      comment: "",
      partnerId: ""
    }
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "shopProviders"
  });

  React.useEffect(() => {
    methods.reset({
      name: shop?.name ?? "",
      address: shop?.address ?? "",
      phoneNumber: shop?.phoneNumber ?? "",
      shopProviders: shop?.shopProviders ?? [{ provider: undefined, customName: undefined }],
      comment: shop?.comment ?? "",
      partnerId: shop?.partner?.id.toString() ?? partner?.id.toString() ?? ""
    });
  }, [shop, partner, methods]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} className="form-grid">
        <InputField
          name="name"
          label="Nom"
          type="text"
          validationRules={{ required: "Ce champ est requis" }}
        />
        <InputField
          name="address"
          label="Adresse"
          type="text"
        />
        <InputField
          name="phoneNumber"
          label="Tel."
          type="text"
        />
        <InputField
          name="comment"
          label="Commentaire"
          type="text"
        />
        {!partner && (
          <PartnerSelect
            name="partnerId"
            label="Partenaire"
            defaultValue={shop?.partner?.id.toString()}
            validationRules={{ required: "Ce champ est requis" }}
          />
        )}
        {partner && (
          <InputField
            name="partnerId"
            type="hidden"
          />
        )}

        {fields.map((field, index) => (
          <fieldset key={field.id}>
            <legend>Fournisseur #{index + 1}</legend>
            <ProviderSelect
              name={`shopProviders[${index}].provider`}
              label="Fournisseur"
              validationRules={{ required: "Ce champ est requis" }}
              defaultValue={field.provider}
            />
            <InputField
              name={`shopProviders[${index}].customName`}
              label="Nom personnalisé"
              type="text"
              validationRules={{ required: "Ce champ est requis" }}
            />
            
            <button type="button" className="btn-delete"  onClick={() => remove(index)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </fieldset>
        ))}
        <button type="button" onClick={() => append({ provider: undefined, customName: undefined })}>
          Ajouter un fournisseur
        </button>

        <div className="buttons-container">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    </FormProvider>
  );
};

export default ShopFormEdit;
