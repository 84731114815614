import React from "react";
import { ShopPerformanceInterface } from "../../interfaces/ShopPerformanceInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import PaginatedTable from "../generic/Table/PaginatedTable";
import { useTranslation } from "react-i18next";
import { formatSecondesInMinutes } from "../../utils/generic/dateUtils";

interface ShopPerformancesTableProps {
  fetchPaginatedShopPerformances: (page: number, limit: number, query: string) => Promise<{ data: ShopPerformanceInterface[]; total: any }>;
  onDelete: (shopPerformance: ShopPerformanceInterface) => void;
  pageSize: number;
}

const ShopPerformancesTable: React.FC<ShopPerformancesTableProps> = ({
  fetchPaginatedShopPerformances,
  onDelete,
  pageSize
}) => {
  const { t: tProviders } = useTranslation('providers');

  const headers = [
    "Partenaire",
    "Fournisseur",
    "Shop",
    "Période",
    "Ventes",
    "Commandes",
    "Temps de préparation",
    "Note moyenne",
    "Action",
  ];

  const formatData = (importEntities: ShopPerformanceInterface[]) => importEntities.map(shopPerformance => ({
    "Partenaire": shopPerformance?.shopProvider.shop?.partner.name || "",
    "Fournisseur": tProviders(shopPerformance?.shopProvider.provider || ""),
    "Shop": shopPerformance?.shopProvider.customName || "",
    "Période": (shopPerformance?.startDate && shopPerformance?.endDate) ? format(shopPerformance.startDate, 'dd/MM/yyyy', { locale: fr }) + " -> " + format(shopPerformance.endDate, 'dd/MM/yyyy', { locale: fr }) : "",
    "Ventes": shopPerformance?.totalSalesAmount + " €" || "",
    "Commandes": shopPerformance?.deliveredOrdersCount || "",
    "Temps de préparation": shopPerformance?.preparationTime ? formatSecondesInMinutes(shopPerformance?.preparationTime) : "",
    "Note moyenne": shopPerformance?.averageRating + "/5" || "",
    "Action": shopPerformance.id ? (
      <>
        <button className="btn-delete" onClick={() => onDelete(shopPerformance)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    ) : null,
  }));

  return (
    <PaginatedTable
      headers={headers}
      columnWidths={["auto", "11em", "auto", "14em", "auto", "auto", "auto", "auto", "10em"]}
      headerAlignments={["left", "left", "left", "left", "left", "left", "left", "left", "center"]}
      contentAlignments={["left", "left", "left", "left", "left", "left", "left", "left", "center"]}
      fetchData={(page, limit, query) => fetchPaginatedShopPerformances(page, limit, query).then(response => ({ data: formatData(response.data), total: response.total }))}
      initialPageSize={pageSize}
    />
  );
};

export default ShopPerformancesTable;
