import UsersList from "../../../components/Admin/User/UsersList";
import "./Users.css";

function Users() {
  return (
    <>
      <div className="admin-users-container">
        <h3>Gestion des utilisateurs</h3>
        <UsersList />
      </div>
    </>
  );
}

export default Users;
