import React, { useCallback, useEffect, useRef, useState } from "react";
import EmailConfigurationsTable from "./EmailConfigurationsTable";
import EmailConfigurationPushModal from "./Modals/EmailConfigurationPushModal";
import { EmailConfigurationInterface } from "../../interfaces/EmailConfigurationInterface";
import { useEntityManager } from "../../hooks/useEntityManager";
import { ApiService } from "../../services/generic/apiService";

interface EmailConfigurationListProps {
}

const EmailConfigurationsList: React.FC<EmailConfigurationListProps> = () => {
  const apiService = new ApiService<EmailConfigurationInterface, EmailConfigurationInterface>('/email-configurations');
  const tableRef = useRef<any>(null); // Reference to the PaginatedTable component

  // State hooks
  const [isEmailConfigurationPushModalOpen, setIsEmailConfigurationPushModalOpen] = useState(false);
  const [selectedEmailConfiguration, setSelectedEmailConfiguration] = useState<EmailConfigurationInterface | null>(null);

  const fetchPaginatedEmailConfigurations = async (page: number, limit: number, query: string) => {
    const response = await apiService.getResponse(undefined, { page, limit, query });
    return {
      data: response.data,
      total: response.headers["x-total-count"] ?? 0,
    };
  };

  const reloadEmailConfigurations = () => {
    if (tableRef.current) {
      tableRef.current.reload(); // Call the reload function of PaginatedTable
    }
  };

  const { submitEntity } = useEntityManager<EmailConfigurationInterface, EmailConfigurationInterface>({ apiService: apiService, callback: reloadEmailConfigurations });

  // Callbacks for emailConfiguration actions
  const openEmailConfigurationPushModal = useCallback((emailConfiguration?: EmailConfigurationInterface) => {
    setSelectedEmailConfiguration(emailConfiguration || null);
    setIsEmailConfigurationPushModalOpen(true);
  }, []);

  const closeEmailConfigurationPushModal = useCallback(() => {
    setIsEmailConfigurationPushModalOpen(false);
    setSelectedEmailConfiguration(null);
  }, []);

  // Handle Forms
  const handleSubmit = useCallback(
    (formData: any) => {
      if (selectedEmailConfiguration) {
        submitEntity(selectedEmailConfiguration, formData);
      } else {
        submitEntity(null, formData);
      }
      closeEmailConfigurationPushModal();
    },
    [submitEntity, selectedEmailConfiguration, closeEmailConfigurationPushModal]
  );

  return (
    <>
      <EmailConfigurationsTable
        ref={tableRef}
        fetchPaginatedEmailConfigurations={fetchPaginatedEmailConfigurations}
        onEdit={openEmailConfigurationPushModal}
        pageSize={10} // Define the number of items per page
      />
      <EmailConfigurationPushModal
        isOpen={isEmailConfigurationPushModalOpen}
        onClose={closeEmailConfigurationPushModal}
        onSubmit={handleSubmit}
        emailConfiguration={selectedEmailConfiguration}
      />
    </>
  );
};

export default EmailConfigurationsList;
