import React, { useCallback, useEffect, useRef, useState } from "react";
import { AutomationExecutionStatusEnum, PartnerInterface, AuditAutomationExecutionInterface } from "../../../interfaces/PartnerInterface";
import "./Audit.css";
import PartnersList from "../generic/PartnersList";
import AutomationPlayConfirmationModal from "../generic/Modals/AutomationPlayConfirmationModal";
import AutomationShowLogsModal from "../generic/Modals/AutomationShowLogsModal";
import { ApiAutomationService } from "../../../services/apiAutomationService";
import { ApiService } from "../../../services/generic/apiService";
import { MessageNotificationInterface, useNotifications } from "../../../context/NotificationContext";
import { useAlert } from "../../../context/generic/AlertContext";
import { getErrorMessage } from "../../../utils/generic/errorUtils";
import { LogsInterface } from "../generic/Interfaces/LogsInterface";


const AutomationAuditComponent: React.FC = ({
}) => {

  const [selectedPartner, setSelectedPartner] = useState<PartnerInterface | null>(
    null
  );
  const [selectedManager, setSelectedManager] = useState<string>(
    ""
  );
  const [isAutomationPlayConfirmationModalOpen, setIsAutomationPlayConfirmationModalOpen] = useState<boolean>(
    false
  );
  const [isShowLogsModalOpen, setIsShowLogsModalModalOpen] = useState<boolean>(
    false
  );
  const [globalAuditAutomationExecution, setGlobalAuditAutomationExecution] = useState<boolean>(false);

  const [partners, setPartners] = useState<PartnerInterface[]>([]);
  const apiAutomationService = new ApiAutomationService();

  const { messages } = useNotifications();
  const latestMessageRef = useRef<MessageNotificationInterface | null>(null);

  const { showAlert } = useAlert();

  useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      if (latestMessage.message !== latestMessageRef.current) {
        latestMessageRef.current = latestMessage.message;
        if (latestMessage.message["task"] === "SYSTEM" && latestMessage.message["section"] === "AUDIT_AUTOMATION_GLOBAL" && latestMessage.message["type"] === "success") {
          setGlobalAuditAutomationExecution(false);
          getPartners();
        }
        if (latestMessage.message["task"] === "SYSTEM" && latestMessage.message["section"] === "AUDIT_AUTOMATION" && latestMessage.message["type"] === "success") {
          getPartners();
        }
      }
    }
  }, [messages]);

  const getPartners = async () => {
    let apiPartnerManagerService = new ApiService<PartnerInterface, PartnerInterface>(`/partners/${selectedManager}`);
    const fetchedPartners = await apiPartnerManagerService.get();
    setPartners(fetchedPartners);
  };

  const handleCloseAutomationPlayConfirmationModal = useCallback(
    () => {
      setSelectedPartner(null);
      setIsAutomationPlayConfirmationModalOpen(false);
    },
    []
  );

  const handleSubmitAutomationPlayConfirmationModal = async (partner: PartnerInterface | null, startDate?: Date, endDate?: Date, simulate?: boolean, force?: boolean) => {
    try {
      let lastExecution: AuditAutomationExecutionInterface | null = await apiAutomationService.auditLaunch(partner, force);
      if (!partner) {
        setGlobalAuditAutomationExecution(true);
      }
    } catch (error: any) {
      showAlert(getErrorMessage(error), "error");
    }
    setIsAutomationPlayConfirmationModalOpen(false);
    getPartners();
  };

  const handleOnPlay = useCallback(
    (partner?: PartnerInterface) => {
      setSelectedPartner(partner ?? null);
      setIsAutomationPlayConfirmationModalOpen(true);
    },
    []
  );

  const handleOnShowLogs = useCallback(
    async (partner: PartnerInterface) => {
      setSelectedPartner(partner);
      setIsShowLogsModalModalOpen(true);
    },
    []
  );


  const handleCloseShowLogsModal = useCallback(
    () => {
      setSelectedPartner(null);
      setIsShowLogsModalModalOpen(false);
    },
    []
  );

  const handleCanPlay = (partner?: PartnerInterface | null) => {
    if (!partner) {
      return !globalAuditAutomationExecution;
    }
    return !partner?.lastAuditAutomationExecution || ![AutomationExecutionStatusEnum.RUNNING, AutomationExecutionStatusEnum.PENDING].includes(partner.lastAuditAutomationExecution.status as AutomationExecutionStatusEnum);
  }


  const handleOnStop = async (partner?: PartnerInterface) => {
    await apiAutomationService.auditStop(partner, { manager: selectedManager });
    getPartners();
  };
  const transformLogs = (logs: AuditAutomationExecutionInterface[]): LogsInterface[] => {
    return logs.map(log => ({
      id: log.id,
      date: new Date(log.updatedAt ?? log.createdAt),
      status: log.status,
      params: {
        id: log.id.toString(),
      },
    }));
  };

  const showLogsCallback = async (page: number, items: number, partner: PartnerInterface) => {
    let logs = await apiAutomationService.getLogs(partner, "audit");
    return transformLogs(logs); // Transform the logs before returning
  };

  return (
    <>
      <PartnersList
        mainActions={false}
        simulateAction={false}
        canPlay={handleCanPlay}
        onPlay={handleOnPlay}
        onShowLogs={handleOnShowLogs}
        onStop={handleOnStop}
        getPartners={getPartners}
        partners={partners}
        selectedManager={selectedManager}
        setSelectedManager={setSelectedManager}
      />

      <AutomationPlayConfirmationModal
        dateRangeSelection={false}
        isOpen={isAutomationPlayConfirmationModalOpen}
        onClose={handleCloseAutomationPlayConfirmationModal}
        onSubmit={handleSubmitAutomationPlayConfirmationModal}
        partner={selectedPartner}
      />

      <AutomationShowLogsModal
        isOpen={isShowLogsModalOpen}
        onClose={handleCloseShowLogsModal}
        partner={selectedPartner}
        callBack={showLogsCallback}
      />
    </>
  );
};

export default AutomationAuditComponent;
