import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { apiService } from '../../services/generic/apiService';
import { useNavigate } from 'react-router-dom';
import { ApiUserService } from '../../services/apiUserService';
import { UserInterface } from '../../interfaces/UserInterface';

type AuthContextType = {
  isAuthenticated: boolean;
  loginUser: (token: string) => Promise<void>;
  logoutUser: () => void;
  user: UserInterface;
};

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const apiUserService = new ApiUserService();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(localStorage.getItem('token') !== null);
  const [user, setUser] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      if (localStorage.getItem('token')) {
        try {
          const user = await apiUserService.fetchUserInfo();
          setUser(user);
          setIsAuthenticated(true);
        } catch (error) {
          console.error("Failed to fetch user", error);
          logoutUser();
        }
      }
    };
    fetchUser();
  }, []);

  const loginUser = async (token: string) => {
    localStorage.setItem('token', token);
    try {
      const user = await apiUserService.fetchUserInfo();
      localStorage.setItem('userInfo', JSON.stringify(user));
      setUser(user);
      setIsAuthenticated(true);
    } catch (error) {
      console.error("Failed to fetch user", error);
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('userInfo');
    setUser(null);
    setIsAuthenticated(false);
    navigate('/login');
  };

  apiService.interceptors.request.use((config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  apiService.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        logoutUser();
      }
      return Promise.reject(error);
    }
  );

  return (
    <AuthContext.Provider value={{ isAuthenticated, loginUser, logoutUser, user }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
