import "./login-layout.css"
import Navbar from "../Navbar/Navbar"
import { Outlet } from "react-router-dom"


export default function LoginLayout() {
  return (
    <>
        <Navbar />
        <div className="login-main-container">
        <Outlet />
        </div>
    </>
  )
}
