import React, { ReactNode, createContext, useContext } from "react"
import { MenuProvider } from "./MenuContext"
import { AlertProvider } from "./generic/AlertContext"
import { AuthProvider } from "./generic/AuthContext"
import { apiService } from '../services/generic/apiService'
import { NotificationProvider } from "./NotificationContext"

type AppProviderProps = {
  children: ReactNode
}
type AppContextType = {
}

const AppContext = createContext<AppContextType | undefined>(undefined)

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {

  apiService.interceptors.request.use((config) => {
    return config;
  });

  return (
    <AppContext.Provider value={{}}>
      <NotificationProvider>
        <AlertProvider>
          <AuthProvider>
            <MenuProvider>{children}</MenuProvider>
          </AuthProvider>
        </AlertProvider>
      </NotificationProvider>
    </AppContext.Provider>
  );
};

export default AppProvider

export const useApp = () => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useApp should be used in AppProvider')
  }
  return context
}
