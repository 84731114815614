import "./Dashboard.css";

function Dashboard() {
  return (
    <>
      <div className="dashboard-container">Dashboard</div>
    </>
  );
}

export default Dashboard;
