import React, { useCallback } from "react";
import { RegisterOptions } from "react-hook-form";
import SelectFetcher from "../../generic/Form/Fields/SelectFetcher";
import { ApiShopService } from "../../../services/apiShopService";
import { useTranslation } from 'react-i18next';
import '../../../i18n';

interface ProviderSelectProps {
  name: string;
  label: string;
  defaultValue?: string
  validationRules?: RegisterOptions;
}

interface ProviderOptionInterface {
    value: string;
    label: string;
}

const ProviderSelect: React.FC<ProviderSelectProps> = ({
  name,
  label,
  defaultValue,
  validationRules,
}) => {
  const { t } = useTranslation('providers');
  const apiShopService = new ApiShopService();
  const loadProviders = useCallback(async () => {
    const providers = await apiShopService.getProviders();
    let options: ProviderOptionInterface[] = [];

    providers.forEach(provider => {
      options.push({ value: provider, label: t(`${provider}`) });
    })
    return options;
  }, []);

  return (
    <SelectFetcher
      name={name}
      label={label}
      loadOptionsFunction={loadProviders}
      defaultValue={defaultValue}
      validationRules={validationRules}
    />
  );
};

export default ProviderSelect;
