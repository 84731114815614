import React, { useEffect, useState } from "react";
import Select from "react-select"; // Import React-Select
import { useFormContext, RegisterOptions } from "react-hook-form";
import { useAlert } from "../../../../context/generic/AlertContext";

export interface Option {
  value: string;
  label: string;
}

interface SelectSearchFetcherProps {
  name: string;
  label: string;
  defaultValue?: string;
  validationRules?: RegisterOptions;
  loadOptionsFunction: () => Promise<Option[]>;
  emptyChoice?: Option;
  onChange?: (selectedOption: Option | null) => void;
  disabled?: boolean;
}

const SelectSearchFetcher: React.FC<SelectSearchFetcherProps> = ({
  name,
  label,
  validationRules,
  defaultValue,
  loadOptionsFunction,
  emptyChoice,
  onChange,
  disabled,
}) => {
  const [options, setOptions] = useState<Option[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { setValue } = useFormContext();
  const { showAlert } = useAlert();

  // Effet pour charger les options à chaque changement de `loadOptionsFunction`
  useEffect(() => {
    const fetchOptions = async () => {
      setIsLoading(true);
      try {
        const loadedOptions = await loadOptionsFunction();
        const finalOptions = emptyChoice ? [emptyChoice, ...loadedOptions] : loadedOptions;
        setOptions(finalOptions);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        showAlert("Unable to fetch options", "error");
      }
    };

    fetchOptions();
  }, [loadOptionsFunction, emptyChoice, showAlert]);

  useEffect(() => {
    if (!isLoading && defaultValue) {
      setValue(name, defaultValue);
    }
  }, [isLoading, defaultValue, setValue, name]);

  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <Select
        id={name}
        options={options}
        isDisabled={disabled}
        isLoading={isLoading}
        defaultValue={options.find(option => option.value === defaultValue) || null}
        onChange={(selected) => {
          setValue(name, selected ? selected.value : null);
          onChange?.(selected || null);
        }}
        placeholder={isLoading ? "Loading..." : "Select an option"}
        isClearable
        onInputChange={(inputValue) => {
          // Met à jour la recherche dans le composant parent
          onChange?.({ value: inputValue, label: inputValue });
        }}
      />
    </div>
  );
};

export default SelectSearchFetcher;
