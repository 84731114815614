import React from "react";
import Table from "../../generic/Table/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faVial, faFileAlt, faStop } from "@fortawesome/free-solid-svg-icons";
import { LogsInterface } from "./Interfaces/LogsInterface";
import { formatDateLocalIsoToFrString, formatDateToLocalISO } from "../../../utils/generic/dateUtils";

interface AutomationLogsTableProps {
  executionLogs: LogsInterface[];
  onForce: (executionLog: LogsInterface) => void;
}

const AutomationLogsTable: React.FC<AutomationLogsTableProps> = ({
  executionLogs,
  onForce,
}) => {

  const formatParams = (params: { [key: string]: string }): string => {
    return Object.entries(params)
      .map(([key, value]) => `${key}: ${value}`)
      .join(", ");
  };

  const headers = [
    'Date d\'exécution',
    'Status',
    'Paramètres',
    "Action",
  ];

  const executionLogsData = executionLogs.map((executionLog) => ({
    'Date d\'exécution': formatDateToLocalISO(executionLog.date),
    'Status': executionLog.status,
    'Paramètres': formatParams(executionLog.params),
    'Action': (
      <button onClick={() => onForce(executionLog)}>
        <FontAwesomeIcon icon={faRedo} />
      </button>
    )
  }));

  return (
    <Table
      headers={headers}
      columnWidths={["10em", "8em", "auto", "auto"]}
      headerAlignments={["left", "left", "center", "center"]}
      contentAlignments={["left", "left", "center", "center"]}
      data={executionLogsData}
    />
  );
};

export default AutomationLogsTable;
