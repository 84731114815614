import React, { useCallback, useEffect, useRef, useState } from "react";
import ShopsTable from "./ShopsTable";
import ShopPushModal from "./Modals/ShopPushModal";
import ShopDeleteConfirmationModal from "./Modals/ShopDeleteConfirmationModal";
import { ShopInterface } from "../../interfaces/ShopInterface";
import { useEntityManager } from "../../hooks/useEntityManager";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiService } from "../../services/generic/apiService";
import { PartnerInterface } from "../../interfaces/PartnerInterface";
import { useAuth } from "../../context/generic/AuthContext";

interface ShopListProps {
  partner?: PartnerInterface;
}

const ShopsList: React.FC<ShopListProps> = ({ partner }) => {
  const { user } = useAuth();
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  const apiService = new ApiService<ShopInterface, ShopInterface>('/shops');
  const tableRef = useRef<any>(null); // Reference to the PaginatedTable component

  // State hooks
  const [isShopPushModalOpen, setIsShopPushModalOpen] = useState(false);
  const [isShopDeleteConfirmationModalOpen, setIsShopDeleteConfirmationModalOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState<ShopInterface | null>(null);

  const fetchPaginatedShops = async (page: number, limit: number, query: string) => {
    const params = partner ? { partnerId: partner.id.toString() } : {};
    const response = await apiService.getResponse(undefined, { page, limit, query, ...params });
    return {
      data: response.data,
      total: response.headers["x-total-count"] ?? 0,
    };
  };

  const reloadShops = () => {
    if (tableRef.current) {
      tableRef.current.reload(); // Call the reload function of PaginatedTable
    }
  };

  const { submitEntity, deleteEntity } = useEntityManager<ShopInterface, ShopInterface>({ apiService: apiService, callback: reloadShops });

  // Callbacks for shop actions
  const openShopPushModal = useCallback((shop?: ShopInterface) => {
    setSelectedShop(shop || null);
    setIsShopPushModalOpen(true);
  }, []);

  const closeShopPushModal = useCallback(() => {
    setIsShopPushModalOpen(false);
    setSelectedShop(null);
  }, []);

  const openDeleteConfirmationModal = useCallback((shop: ShopInterface) => {
    setSelectedShop(shop);
    setIsShopDeleteConfirmationModalOpen(true);
  }, []);

  const closeDeleteConfirmationModal = useCallback(() => {
    setIsShopDeleteConfirmationModalOpen(false);
    setSelectedShop(null);
  }, []);

  // Handle Forms
  const handleSubmit = useCallback(
    (formData: any) => {
      if (selectedShop) {
        submitEntity(selectedShop, formData);
      } else {
        submitEntity(null, formData);
      }
      closeShopPushModal();
    },
    [submitEntity, selectedShop, closeShopPushModal]
  );

  const handleDelete = useCallback(() => {
    if (selectedShop) {
      deleteEntity(selectedShop.id);
    }
    setIsShopDeleteConfirmationModalOpen(false);
  }, [deleteEntity, selectedShop]);

  return (
    <>
      {isAdmin && (
        <button onClick={() => openShopPushModal()}>
          <FontAwesomeIcon icon={faPlus} />
        </button>
      )}
      <ShopsTable
        ref={tableRef}
        fetchPaginatedShops={fetchPaginatedShops}
        onDelete={openDeleteConfirmationModal}
        onEdit={openShopPushModal}
        pageSize={10} // Définissez ici le nombre d'éléments par page
      />
      <ShopPushModal
        isOpen={isShopPushModalOpen}
        onClose={closeShopPushModal}
        onSubmit={handleSubmit}
        shop={selectedShop}
        partner={partner}
      />
      <ShopDeleteConfirmationModal
        isOpen={isShopDeleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onDelete={handleDelete}
        shop={selectedShop}
      />
    </>
  );
};

export default ShopsList;
