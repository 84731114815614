import React from "react";
import { useForm } from "react-hook-form";
import Modal from "../../generic/Modal/Modal";
import { EmailConfigurationInterface } from "../../../interfaces/EmailConfigurationInterface";
import EmailConfigurationFormEdit from "../Forms/EmailConfigurationFormEdit";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (data: any) => void;
  emailConfiguration?: EmailConfigurationInterface | null;
}

const EmailConfigurationPushModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onSubmit,
  emailConfiguration,
}) => {
  const methods = useForm();

  React.useEffect(() => {
    // Reset form with new or existing emailConfiguration data
    methods.reset({
      partnerId: emailConfiguration?.partner.id ?? "",
      automation: emailConfiguration?.automation ?? "",
      subject: emailConfiguration?.subject ?? "",
    });
  }, [emailConfiguration, methods.reset]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={emailConfiguration ? "Modifier une configuration email" : "Ajouter une configuration email"}
      size="large"
    >
      <EmailConfigurationFormEdit emailConfiguration={emailConfiguration} onSubmit={onSubmit} />
    </Modal>
  );
};

export default EmailConfigurationPushModal;
