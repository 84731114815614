import React, { createContext, useContext, useState, ReactNode, useCallback } from "react";
import Alert from "../../components/generic/Alert/Alert";

type AlertType = {
  id: number;
  message: string;
  type: "success" | "error" | "warning";
  duration?: number;
};

type AlertContextType = {
  showAlert: (message: string, type: "success" | "error" | "warning", duration?: number) => number;
  closeAlert: (id: number) => void;
};

const AlertContext = createContext<AlertContextType | undefined>(undefined);

export const useAlert = () => {
  const context = useContext(AlertContext);
  if (context === undefined) {
    throw new Error("useAlert must be used within an AlertProvider");
  }
  return context;
};

type AlertProviderProps = {
  children: ReactNode;
};

let nextAlertId = 0;

export const AlertProvider: React.FC<AlertProviderProps> = ({ children }) => {
  const [alerts, setAlerts] = useState<AlertType[]>([]);

  const showAlert = useCallback((message: string, type: "success" | "error" | "warning", duration = 5000) => {
    const id = nextAlertId++;
    setAlerts((prevAlerts) => [...prevAlerts, { id, message, type, duration }]);
    if(duration !== -1){
      setTimeout(() => closeAlert(id), duration);
    }
    return id;
  }, []);

  const closeAlert = useCallback((id: number) => {
    setAlerts((prevAlerts) => prevAlerts.filter(alert => alert.id !== id));
  }, []);

  return (
    <AlertContext.Provider value={{ showAlert, closeAlert }}>
      {children}
      {alerts.map((alert, index) => (
        <Alert key={alert.id} message={alert.message} type={alert.type} duration={alert.duration} position={index} />
      ))}
    </AlertContext.Provider>
  );
};
