import React, { forwardRef } from "react";
import PaginatedTable from "../generic/Table/PaginatedTable";
import { ShopInterface } from "../../interfaces/ShopInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../context/generic/AuthContext";

interface ShopsTableProps {
  fetchPaginatedShops: (page: number, limit: number, query: string) => Promise<{ data: ShopInterface[]; total: any }>;
  onEdit: (shop: ShopInterface) => void;
  onDelete: (shop: ShopInterface) => void;
  pageSize: number;
}

const ShopsTable = forwardRef<any, ShopsTableProps>(({
  fetchPaginatedShops,
  onEdit,
  onDelete,
  pageSize
}, ref) => {
  const { user } = useAuth();
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  const { t: tProviders } = useTranslation('providers');
  const headers = [
    "Partenaire",
    "Account Manager",
    "Nom",
    "Adresse",
    "Tel.",
    "Fournisseurs",
    "Commentaire",
    "Action",
  ];

  const formatData = (shops: ShopInterface[]) => shops.map(shop => ({
    "Partenaire": shop.partner?.name || "", 
    "Account Manager": shop?.partner?.manager?.login || "",
    "Nom": shop.name || "",
    "Adresse": shop.address || "",
    "Tel.": shop.phoneNumber || "",
    "Fournisseurs": (shop.shopProviders || []).length > 0 ? (
      <ul style={{ margin: 0 }}>
        {shop.shopProviders.map((provider, index) => (
          <li key={index} style={{ margin: 0, padding: 0 }}>
            {`${provider.customName} (${tProviders(provider.provider)})`}
          </li>
        ))}
      </ul>
    ) : "",
    "Commentaire": shop.comment || "",
    "Action": shop.id ? (
      <>
        {isAdmin && (
          <>
            <button onClick={() => onEdit(shop)}>
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button className="btn-delete" onClick={() => onDelete(shop)}>
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </>
        )}
      </>
    ) : null,
  }));

  return (
    <PaginatedTable
      ref={ref}
      headers={headers}
      columnWidths={["auto", "auto", "auto", "12em", "auto", "auto", "auto", "7em"]}
      headerAlignments={["left", "left", "left", "left", "left", "left", "left", "center"]}
      contentAlignments={["left", "left", "left", "left", "left", "left", "left", "center"]}
      fetchData={(page, limit, query) => fetchPaginatedShops(page, limit, query).then(response => ({ data: formatData(response.data), total: response.total }))}
      initialPageSize={pageSize}
    />
  );
});

export default ShopsTable;
