import React, { useEffect } from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';
import Select, { MultiValue, SingleValue } from 'react-select';

export interface Option {
  value: string;
  label: string;
}

interface MultiSelectFieldProps {
  name: string;
  label?: string;
  options: Option[];
  defaultValue?: string | string[];
  validationRules?: RegisterOptions;
  onChange?: (value: MultiValue<Option> | SingleValue<Option>) => void;
  disabled?: boolean;
}

const MultiSelectField: React.FC<MultiSelectFieldProps> = ({
  name,
  label,
  options,
  defaultValue,
  validationRules,
  onChange,
  disabled,
}) => {
  const { setValue, formState: { errors } } = useFormContext();

  const errorMessage = (name: string): string | null => {
    const error = errors[name];
    return error && typeof error.message === 'string' ? error.message : null;
  };

  useEffect(() => {
    if (defaultValue) {
      setValue(name, defaultValue);
    }
  }, [options, name, defaultValue, setValue]);

  return (
    <div className="form-group">
      {label && <label htmlFor={name}>{label}</label>}
      <Select
        id={name}
        options={options}
        defaultValue={options.filter(option => {
          if (Array.isArray(defaultValue)) {
            return defaultValue.includes(option.value);
          } else {
            return option.value === defaultValue;
          }
        })}
        isMulti={true}
        onChange={(selectedOptions) => {
          const value = (selectedOptions as MultiValue<Option>).map(option => option.value);
          setValue(name, value);
          if (onChange) {
            onChange(selectedOptions);
          }
        }}
        isDisabled={disabled}
      />
      {errorMessage(name) && <p className="error">{errorMessage(name)}</p>}
    </div>
  );
};

export default MultiSelectField;
