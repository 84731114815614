import React, { useCallback, useEffect, useState } from "react";
import { ApiShopService } from "../../../services/apiShopService";
import { RegisterOptions } from "react-hook-form";
import { ShopInterface } from "../../../interfaces/ShopInterface";
import { Option } from "../../generic/Form/Fields/Select";
import SelectSearchFetcher from "../../generic/Form/Fields/SelectSearchFetcher";

interface ShopSelectProps {
  name: string;
  label: string;
  selectedShopId: number | null;
  onSelectedShopIdChange: (shopId: number | null) => void;
  defaultValue?: string;
  validationRules?: RegisterOptions;
  disabled?: boolean;
}

interface ShopOptionInterface {
  value: string;
  label: string;
}

const ShopSelect: React.FC<ShopSelectProps> = ({
  name,
  label,
  selectedShopId,
  onSelectedShopIdChange,
  defaultValue,
  validationRules,
  disabled,
}) => {
  const apiShopService = new ApiShopService();
  const [searchTerm, setSearchTerm] = useState<string>(""); // État pour stocker la recherche
  const [debouncedSearch, setDebouncedSearch] = useState<string>("");

  // Fonction avec debounce pour réduire les appels API
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearch(searchTerm);
    }, 300); // 300ms de délai
    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Chargement des options avec le terme de recherche
  const loadShops = useCallback(async () => {
    const shops = await apiShopService.search(debouncedSearch, 1, 100); // API avec paramètre de recherche
    let options: ShopOptionInterface[] = [];
    shops.forEach((shop: ShopInterface) => {
      options.push({ value: shop.id.toString(), label: shop.name });
    });
    return options;
  }, [debouncedSearch]);

  // Gestion de la saisie dans le champ
  const handleSearchChange = (search: string) => {
    setSearchTerm(search); // Mettez à jour le terme de recherche
  };

  // Mise à jour de l'identifiant sélectionné
  const handleShopChange = (selectedOption: Option | null) => {
    if (selectedOption && selectedOption.value && parseInt(selectedOption.value) !== selectedShopId) {
      const shopId = parseInt(selectedOption.value);
      onSelectedShopIdChange(shopId);
      console.log(selectedOption);
    }
  };  

  return (
    <SelectSearchFetcher
      name={name}
      label={label}
      loadOptionsFunction={loadShops} // Fonction de chargement dynamique
      defaultValue={defaultValue}
      validationRules={validationRules}
      disabled={disabled}
      onChange={(selectedOption) => {
        handleSearchChange(selectedOption?.label || "");
        handleShopChange(selectedOption);
      }}
    />
  );
};


export default ShopSelect;
