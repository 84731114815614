import React, { useCallback } from "react";
import { ApiUserService } from "../../../services/apiUserService";
import { RegisterOptions } from "react-hook-form";
import SelectFetcher from "../../generic/Form/Fields/SelectFetcher";

interface SupervisorSelectProps {
  name: string;
  label: string;
  defaultValue?: string;
  validationRules?: RegisterOptions;
  emptyLabel?: string;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

interface SupervisorOptionInterface {
  value: string;
  label: string;
}

const SupervisorSelect: React.FC<SupervisorSelectProps> = ({
  name,
  label,
  defaultValue,
  validationRules,
  emptyLabel,
  onChange,
}) => {
  const apiUserService = new ApiUserService();
  const loadSupervisors = useCallback(async () => {
    const users = await apiUserService.get(undefined, { role: "ROLE_ADMIN" });
    let options: SupervisorOptionInterface[] = [];

    users.forEach(user => {
      options.push({ value: user.id.toString(), label: user.login });
    });
    return options;
  }, []);

  return (
    <SelectFetcher
      name={name}
      label={label}
      loadOptionsFunction={loadSupervisors}
      defaultValue={defaultValue}
      validationRules={validationRules}
      emptyChoice={emptyLabel ? { value: "", label: emptyLabel ?? "" } : undefined}
      onChange={onChange}
    />
  );
};

export default SupervisorSelect;
