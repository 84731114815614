import OrdersList from "../../components/Order/OrderList";
import "./Orders.css";

function Orders() {
  return (
    <>
      <div className="imports-container">
        <h3>Liste des commandes</h3>
        <OrdersList />
      </div>
    </>
  );
}

export default Orders;
