import React from 'react';
import Modal from '../../generic/Modal/Modal';
import { PartnerInterface } from '../../../interfaces/PartnerInterface';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: () => void;
  partner: PartnerInterface | null;
}

const PartnerDeleteConfirmationModal: React.FC<Props> = ({ isOpen, onClose, onDelete, partner }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Confirmez vous cette action ?" size="large">
      <div>
        Confirmez-vous la suppression du partenaire "{partner?.name}" ?
        <div className="buttons-container">
          <button className="btn-delete" onClick={onDelete}>
            Confirmer
          </button>
          <button onClick={onClose}>Annuler</button>
        </div>
      </div>
    </Modal>
  );
};

export default PartnerDeleteConfirmationModal;
