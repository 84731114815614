import ImportsList from "../../components/Import/ImportList";
import "./Imports.css";

function Imports() {
  return (
    <>
      <div className="imports-container">
        <h3>Gestion des imports</h3>
        <ImportsList />
      </div>
    </>
  );
}

export default Imports;
