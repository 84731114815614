import ResetPassword from './ResetPassword';

const CreatePassword: React.FC = () => {

  return (
    <ResetPassword create={true} />
  );
};

export default CreatePassword;
