import React, { useCallback, useRef, useState } from "react";
import PartnersTable from "./PartnersTable";
import PartnerPushModal from "./Modals/PartnerPushModal";
import PartnerDeleteConfirmationModal from "./Modals/PartnerDeleteConfirmationModal";
import { PartnerInterface } from "../../interfaces/PartnerInterface";
import { useEntityManager } from "../../hooks/useEntityManager";
import { faPlus, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApiPartnerService } from "../../services/apiPartnerService";
import "./PartnersList.css";
import ShopsList from "../Shop/ShopsList";
import { useAuth } from "../../context/generic/AuthContext";
import { ApiImportService } from "../../services/apiImportService";
import PaginatedTable from "../generic/Table/PaginatedTable"; // Import the PaginatedTable component

const PartnersList: React.FC = () => {
  const { user } = useAuth();
  const isAdmin = user && user.roles && user.roles.includes('ROLE_ADMIN');

  const apiService = new ApiPartnerService();
  const apiImportService = new ApiImportService();
  const tableRef = useRef<any>(null); // Reference to the PaginatedTable component

  // State hooks
  const [isPartnerPushModalOpen, setIsPartnerPushModalOpen] = useState(false);
  const [isPartnerShopsEditionOpen, setIsPartnerShopsEditionOpen] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<PartnerInterface | null>(null);
  const [isPartnerDeleteConfirmationModalOpen, setIsPartnerDeleteConfirmationModalOpen] = useState(false);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const fetchPaginatedPartners = async (page: number, limit: number, query: string) => {
    const response = await apiService.getResponse(undefined, { page, limit, query });
    return {
      data: response.data,
      total: response.headers["x-total-count"] ?? 0,
    };
  };

  const reloadPartners = () => {
    if (tableRef.current) {
      tableRef.current.reload(); // Call the reload function of PaginatedTable
    }
  };

  const { submitEntity, deleteEntity } = useEntityManager<PartnerInterface, PartnerInterface>({ apiService: apiService, callback: reloadPartners });

  // Callbacks for partner actions
  const openPartnerPushModal = useCallback((partner?: PartnerInterface) => {
    setSelectedPartner(partner || null);
    setIsPartnerPushModalOpen(true);
  }, []);

  const closePartnerPushModal = useCallback(() => {
    setIsPartnerPushModalOpen(false);
    setSelectedPartner(null);
  }, []);

  const showPartnerShopEdition = useCallback((partner?: PartnerInterface) => {
    setSelectedPartner(partner || null);
    setIsPartnerShopsEditionOpen(true);
  }, []);

  const closeShopEdition = useCallback(() => {
    setIsPartnerShopsEditionOpen(false);
    setSelectedPartner(null);
  }, []);

  const openDeleteConfirmationModal = useCallback((partner: PartnerInterface) => {
    setSelectedPartner(partner);
    setIsPartnerDeleteConfirmationModalOpen(true);
  }, []);

  const closeDeleteConfirmationModal = useCallback(() => {
    setIsPartnerDeleteConfirmationModalOpen(false);
    setSelectedPartner(null);
  }, []);

  // Handle Forms
  const handleSubmit = useCallback(
    (formData: any) => {
      if (selectedPartner) {
        submitEntity(selectedPartner, formData);
      } else {
        submitEntity(null, formData);
      }
      closePartnerPushModal();
    },
    [submitEntity, selectedPartner, closePartnerPushModal]
  );

  const handleDelete = useCallback(() => {
    if (selectedPartner) {
      deleteEntity(selectedPartner.id);
    }
    setIsPartnerDeleteConfirmationModalOpen(false);
  }, [deleteEntity, selectedPartner]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];
      handleFileUpload(file);
    }
  };

  const handleFileUpload = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      await apiImportService.importPartners(formData);
      reloadPartners(); // Refresh the list after successful import
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  const triggerFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <div className={isPartnerShopsEditionOpen ? "partnersListContainerHidden" : ""}>
        {
          isPartnerShopsEditionOpen && 
          <div className="partnersListContainerHiddenOverlay"></div>
        }
        {isAdmin && (
          <div>
            <button onClick={() => openPartnerPushModal()}>
              <FontAwesomeIcon icon={faPlus} />
            </button>
            <button onClick={triggerFileInput}>
              <FontAwesomeIcon icon={faUpload} />
            </button>
            <input
              type="file"
              accept=".xlsx"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </div>
        )}
        <PartnersTable
          ref={tableRef}
          fetchPaginatedPartners={fetchPaginatedPartners}
          onEdit={openPartnerPushModal}
          onEditShops={showPartnerShopEdition}
          onDelete={openDeleteConfirmationModal}
          pageSize={10} // Définissez ici le nombre d'éléments par page
        />
        <PartnerPushModal
          isOpen={isPartnerPushModalOpen}
          onClose={closePartnerPushModal}
          onSubmit={handleSubmit}
          partner={selectedPartner}
        />
        <PartnerDeleteConfirmationModal
          isOpen={isPartnerDeleteConfirmationModalOpen}
          onClose={closeDeleteConfirmationModal}
          onDelete={handleDelete}
          partner={selectedPartner}
        />
      </div>
      {
        isPartnerShopsEditionOpen && selectedPartner &&
        <div className="shopManagementPanel">
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", width: "100%" }}>
            <h4>Shops du partenaire {selectedPartner.name}</h4>
            <button onClick={closeShopEdition} style={{ cursor: "pointer" }}>
              Retour aux partenaires
            </button>
          </div>
          <ShopsList partner={selectedPartner} />
        </div>
      }
    </>
  );
};

export default PartnersList;
