import React, { useCallback, useEffect, useRef, useState } from "react";
import ImportsTable from "./ImportTable";
import ImportDeleteConfirmationModal from "./Modals/ImportDeleteConfirmationModal";
import { ImportInterface } from "../../interfaces/ImportInterface";
import { useEntityManager } from "../../hooks/useEntityManager";
import { ApiService } from "../../services/generic/apiService";
import { ApiImportService } from "../../services/apiImportService";
import { ReportInterface } from "../../interfaces/ReportInterface";
import ReportMapModal from "../Report/Modals/ReportMapModal";
import { MessageNotificationInterface, useNotifications } from "../../context/NotificationContext";

const ImportsList: React.FC = () => {
  const apiService = new ApiService<ImportInterface, ImportInterface>('/import');
  const apiServiceReport = new ApiService<ReportInterface, ReportInterface>('/reports');
  const apiImportService = new ApiImportService();
  const { messages } = useNotifications();
  const latestMessageRef = useRef<MessageNotificationInterface | null>(null);

  const [isImportDeleteConfirmationModalOpen, setIsImportDeleteConfirmationModalOpen] = useState(false);
  const [isMapModalOpen, setIsMapModalOpen] = useState(false);
  const [selectedImport, setSelectedImport] = useState<ImportInterface | null>(null);

  const paginatedTableRef = useRef<{ reload: () => void }>(null);

  const fetchPaginatedImports = async (page: number, limit: number, query: string) => {
    const response = await apiService.getResponse(undefined, { page, limit, query });
    return {
      data: response.data,
      total: response.headers["x-total-count"] ?? 0,
    };
  };

  const { deleteEntity } = useEntityManager<ImportInterface, ImportInterface>({
    apiService: apiService,
    callback: () => paginatedTableRef?.current?.reload()
  });

  const { submitEntity } = useEntityManager<ReportInterface, ReportInterface>({
    apiService: apiServiceReport,
    callback: () => paginatedTableRef?.current?.reload()
  });

  const openDeleteConfirmationModal = useCallback((importEntity: ImportInterface) => {
    setSelectedImport(importEntity);
    setIsImportDeleteConfirmationModalOpen(true);
  }, []);

  const closeDeleteConfirmationModal = useCallback(() => {
    setIsImportDeleteConfirmationModalOpen(false);
    setSelectedImport(null);
  }, []);

  const openMapModal = useCallback((importEntity: ImportInterface) => {
    setSelectedImport(importEntity);
    setIsMapModalOpen(true);
  }, []);

  const closeMapModal = useCallback(() => {
    setIsMapModalOpen(false);
    setSelectedImport(null);
  }, []);

  const handleRetry = async (importEntity: ImportInterface) => {
    await apiImportService.retry(importEntity);
    paginatedTableRef?.current?.reload();
  };

  const handleStop = async (importEntity: ImportInterface) => {
    await apiImportService.stop(importEntity);
    paginatedTableRef?.current?.reload();
  };

  const handleDelete = useCallback(() => {
    if (selectedImport) {
      deleteEntity(selectedImport.id);
    }
    setIsImportDeleteConfirmationModalOpen(false);
  }, [deleteEntity, selectedImport]);

  const handleSubmit = useCallback((formData: any) => {
    if (selectedImport?.report) {
      submitEntity(selectedImport?.report, formData);
      handleRetry(selectedImport);
    }
    closeMapModal();
  }, [submitEntity, selectedImport]);


  useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      if (latestMessage.message !== latestMessageRef.current) {
        latestMessageRef.current = latestMessage.message;
        if (latestMessage.message["section"] === "IMPORT") {
          paginatedTableRef?.current?.reload();
        }
      }
    }
  }, [messages]);

  return (
    <>
      <ImportsTable
        ref={paginatedTableRef}
        fetchPaginatedImports={fetchPaginatedImports}
        onRetry={handleRetry}
        onStop={handleStop}
        onMap={openMapModal}
        onDelete={openDeleteConfirmationModal}
        pageSize={10}
      />
      <ImportDeleteConfirmationModal
        isOpen={isImportDeleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onDelete={handleDelete}
        importEntity={selectedImport}
      />
      <ReportMapModal
        isOpen={isMapModalOpen}
        onClose={closeMapModal}
        onSubmit={handleSubmit}
        importEntity={selectedImport}
      />
    </>
  );
};

export default ImportsList;
