import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ApiUserService } from '../../services/apiUserService';
import { useAlert } from '../../context/generic/AlertContext';
import { useAuth } from '../../context/generic/AuthContext';

const ChangePassword: React.FC = () => {
  const apiUserService = new ApiUserService();
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const { showAlert } = useAlert();
  const { user } = useAuth();
  const location = useLocation();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    if (password !== confirmPassword) {
      showAlert("Les mots de passe ne correspondent pas", "error");
      return;
    }

    try {
      await apiUserService.changePassword(user.id, password);
      showAlert("Mot de passe réinitialisé avec succès", "success");
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      showAlert("Une erreur s'est produite lors de la réinitialisation du mot de passe", "error");
    }
  };

  return (
    <div className="reset-password-container">
      <form onSubmit={handleSubmit}>
        <h2>Réinitialiser le mot de passe</h2>
        <div className="form-element-container">
          <label htmlFor="password">Nouveau mot de passe</label>
          <input
            id="password"
            type="password"
            placeholder="Nouveau mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-element-container">
          <label htmlFor="confirmPassword">Confirmer le mot de passe</label>
          <input
            id="confirmPassword"
            type="password"
            placeholder="Confirmer le mot de passe"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-submit-container">
          <button type="submit">Réinitialiser le mot de passe</button>
        </div>
      </form>
    </div>
  );
};

export default ChangePassword;
