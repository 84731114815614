import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import SideBar from "../SideBar/Sidebar";
import "./main-layout.css";
import { useEffect, useRef } from "react";
import { MessageNotificationInterface, useNotifications } from "../../context/NotificationContext";
import { useAlert } from "../../context/generic/AlertContext";

export default function MainLayout() {
  
  const { messages } = useNotifications();
  const latestMessageRef = useRef<MessageNotificationInterface | null>(null);
  const { showAlert } = useAlert();

  useEffect(() => {
    if (messages.length > 0) {
      const latestMessage = messages[messages.length - 1];
      if (latestMessage.message !== latestMessageRef.current) {
        latestMessageRef.current = latestMessage.message;
        if(latestMessage.message.task === "DISPLAY"){
          showAlert(latestMessage.message.message, latestMessage.message.type);
        }
      }
    }
  }, [messages]);
  return (
    <>
      <Navbar />
      <div className="main-container">
        <SideBar />
        <div className="page-container">
          <Outlet />
        </div>
      </div>
    </>
  );
}
