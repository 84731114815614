import ShopsList from "../../components/Automations/ShopsList";
import "./Automations.css";

function Automations() {
  return (
    <>
      <div className="shops-container">
        <h3>Paramétrage des automatisations</h3>
        <ShopsList />
      </div>
    </>
  );
}

export default Automations;
