import React, { useCallback, useState } from "react";
import ShopPerformancesTable from "./ShopPerformanceTable";
import ShopPerformanceDeleteConfirmationModal from "./Modals/ShopPerformanceDeleteConfirmationModal";
import { ShopPerformanceInterface } from "../../interfaces/ShopPerformanceInterface";
import { useEntityManager } from "../../hooks/useEntityManager";
import { ApiService } from "../../services/generic/apiService";

interface ShopPerformanceListProps {
}

const ShopPerformancesList: React.FC<ShopPerformanceListProps> = ({ }) => {
  const apiService = new ApiService<ShopPerformanceInterface, ShopPerformanceInterface>('/shop_performances');
  // State hooks
  const [
    isShopPerformanceDeleteConfirmationModalOpen,
    setIsShopPerformanceDeleteConfirmationModalOpen,
  ] = useState(false);
  const [selectedShopPerformance, setSelectedShopPerformance] = useState<ShopPerformanceInterface | null>(
    null
  );

  const fetchPaginatedShopPerformances = async (page: number, limit: number, query: string) => {
    const response = await apiService.getResponse(undefined, { page, limit, query });
    return {
      data: response.data,
      total: response.headers["x-total-count"] ?? 0,
    };
  };

  const reloadShopPerformances = () => {  };
  const { deleteEntity } = useEntityManager<ShopPerformanceInterface, ShopPerformanceInterface>({ apiService: apiService, callback: reloadShopPerformances });

  // Callbacks for ShopPerformance actions
  const openDeleteConfirmationModal = useCallback((ShopPerformance: ShopPerformanceInterface) => {
    setSelectedShopPerformance(ShopPerformance);
    setIsShopPerformanceDeleteConfirmationModalOpen(true);
  }, []);

  const closeDeleteConfirmationModal = useCallback(() => {
    setIsShopPerformanceDeleteConfirmationModalOpen(false);
    setSelectedShopPerformance(null);
  }, []);

  //Handle Forms

  const handleDelete = useCallback(() => {
    if (selectedShopPerformance) {
      deleteEntity(selectedShopPerformance.id);
    }
    setIsShopPerformanceDeleteConfirmationModalOpen(false);
  }, [deleteEntity, selectedShopPerformance]);

  return (
    <>
      <ShopPerformancesTable
        fetchPaginatedShopPerformances={fetchPaginatedShopPerformances}
        onDelete={openDeleteConfirmationModal}
        pageSize={10} // Définissez ici le nombre d'éléments par page
      />
      <ShopPerformanceDeleteConfirmationModal
        isOpen={isShopPerformanceDeleteConfirmationModalOpen}
        onClose={closeDeleteConfirmationModal}
        onDelete={handleDelete}
        shopPerformance={selectedShopPerformance}
      />
    </>
  );
};

export default ShopPerformancesList;
