import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import Table, { TableProps } from './Table';
import './PaginatedTable.css'; // Assurez-vous d'importer le fichier CSS

interface PaginatedTableProps extends Omit<TableProps, 'data'> {
  fetchData: (page: number, limit: number, searchQuery: string) => Promise<{ data: any[]; total: number }>;
  initialPageSize: number;
}

const PaginatedTable = forwardRef(({
  headers,
  columnWidths,
  headerAlignments,
  contentAlignments,
  rowClassName,
  fetchData,
  initialPageSize
}: PaginatedTableProps, ref) => {
  const [data, setData] = useState<{ [key: string]: React.ReactNode }[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [pageSize, setPageSize] = useState(initialPageSize);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    loadData(currentPage, pageSize, debouncedSearchQuery);
  }, [currentPage, pageSize, debouncedSearchQuery]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [debouncedSearchQuery]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setDebouncedSearchQuery(searchQuery);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchQuery]);

  useImperativeHandle(ref, () => ({
    reload: () => {
      loadData(currentPage, pageSize, debouncedSearchQuery);
    }
  }));

  const loadData = async (page: number, limit: number, query: string) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetchData(page, limit, query);
      setData(response.data);
      setTotalPages(Math.ceil(response.total / limit));
    } catch (error) {
      setError('Failed to fetch data');
      console.error('Failed to fetch data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePageClick = (page: number) => {
    setCurrentPage(page);
  };

  const handlePageSizeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setPageSize(Number(event.target.value));
    setCurrentPage(1); // Reset to first page when page size changes
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // Reset to first page when search query changes
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageNumbersToShow = windowWidth > 600 ? 5 : 3;
    const startPage = Math.max(currentPage - 2, 1);
    const endPage = Math.min(currentPage + 2, totalPages);

    if (startPage > 1) {
      pageNumbers.push(
        <button key={1} onClick={() => handlePageClick(1)} disabled={1 === currentPage}>
          1
        </button>
      );
      if (startPage > 2) {
        pageNumbers.push(<span key="start-ellipsis">...</span>);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <button key={i} onClick={() => handlePageClick(i)} disabled={i === currentPage}>
          {i}
        </button>
      );
    }

    if (endPage < totalPages) {
      if (endPage < totalPages - 1) {
        pageNumbers.push(<span key="end-ellipsis">...</span>);
      }
      pageNumbers.push(
        <button key={totalPages} onClick={() => handlePageClick(totalPages)} disabled={totalPages === currentPage}>
          {totalPages}
        </button>
      );
    }

    return pageNumbers;
  };

  return (
    <div>
      <div className="table-controls">
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchQuery}
          onChange={handleSearchChange}
          className="search-input"
        />
      </div>
      <div className="table-container">
        <Table
          headers={headers}
          columnWidths={columnWidths}
          headerAlignments={headerAlignments}
          contentAlignments={contentAlignments}
          data={data}
          rowClassName={rowClassName}
        />
      </div>
      <div className="pagination-controls">
        <button onClick={handlePreviousClick} disabled={currentPage === 1}>
          Précédent
        </button>
        {renderPageNumbers()}
        <button onClick={handleNextClick} disabled={currentPage === totalPages}>
          Suivant
        </button>
        <span>Page {currentPage} sur {totalPages}</span>
        <select onChange={handlePageSizeChange} value={pageSize}>
          <option value={5}>5</option>
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
      </div>
      {loading && <p className="loading-message">Loading...</p>}
      {error && <p className="error-message">{error}</p>}
    </div>
  );
});

export default PaginatedTable;
