import React, { forwardRef } from "react";
import { ImportInterface, ImportStateEnum } from "../../interfaces/ImportInterface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faRefresh, faSlidersH, faStop } from "@fortawesome/free-solid-svg-icons";
import { differenceInSeconds, format, formatDistance, formatDistanceToNow } from 'date-fns';
import { fr } from 'date-fns/locale';
import PaginatedTable from "../generic/Table/PaginatedTable";

interface ImportsTableProps {
  fetchPaginatedImports: (page: number, limit: number, query: string) => Promise<{ data: ImportInterface[]; total: any }>;
  onRetry: (importEntity: ImportInterface) => void;
  onStop: (importEntity: ImportInterface) => void;
  onMap: (importEntity: ImportInterface) => void;
  onDelete: (importEntity: ImportInterface) => void;
  pageSize: number;
}

const ImportsTable = forwardRef<{
  reload: () => void;
}, ImportsTableProps>(({
  fetchPaginatedImports,
  onRetry,
  onStop,
  onMap,
  onDelete,
  pageSize
}, ref) => {
  const headers = [
    "Report",
    "Date",
    "Fichier",
    "Elements traités",
    "Elements importés",
    "Statut",
    "Temps d'exécution",
    "Action",
  ];

  const formatExecutionTime = (start: Date | null | undefined, end: Date | null | undefined, state: ImportStateEnum) => {
    if (!start) return "";
    const startTime = new Date(start);
    let endTime;
    if (end) {
        endTime = new Date(end);
    } else {
        return "";
    }

    const durationInSeconds = differenceInSeconds(endTime, startTime);
    return formatDistance(0, durationInSeconds * 1000, { locale: fr });
};

  const formatData = (importEntities: ImportInterface[]) => importEntities.map(importEntity => ({
    "Report": importEntity?.report?.label || "",
    "Date": importEntity?.createdAt ? format(new Date(importEntity.createdAt), 'dd/MM/yyyy HH:mm:ss', { locale: fr }) : "",
    "Fichier": importEntity?.originalFileName || "",
    "Elements traités": importEntity?.itemTreatedCount || "0",
    "Elements importés": importEntity?.itemImportedCount || "0",
    "Statut": importEntity?.state || "",
    "Temps d'exécution": (importEntity?.state === ImportStateEnum.PROCESSING) ? "En cours (" + formatExecutionTime(importEntity?.startProcessedAt, importEntity?.endProcessedAt, importEntity?.state) + ")" : formatExecutionTime(importEntity?.startProcessedAt, importEntity?.endProcessedAt, importEntity?.state),
    "Action": importEntity.id ? (
      <>
      {importEntity?.state !== ImportStateEnum.PROCESSING &&
        <button className="btn-retry" onClick={() => onRetry(importEntity)}>
          <FontAwesomeIcon icon={faRefresh} />
        </button>
      }
      {importEntity?.state === ImportStateEnum.PROCESSING &&
        <button className="btn-stop" onClick={() => onStop(importEntity)}>
          <FontAwesomeIcon icon={faStop} />
        </button>
      }
        {importEntity?.state === ImportStateEnum.BAD_FIELDS &&
          <button className="btn-map" onClick={() => onMap(importEntity)}>
            <FontAwesomeIcon icon={faSlidersH} />
          </button>
        }
        <button className="btn-delete" onClick={() => onDelete(importEntity)}>
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </>
    ) : null,
  }));

  return (
    <PaginatedTable
      ref={ref}
      headers={headers}
      columnWidths={["auto", "11em", "auto", "12em", "auto", "10em"]}
      headerAlignments={["left", "left", "left", "left", "left", "center"]}
      contentAlignments={["left", "left", "left", "left", "left", "center"]}
      fetchData={(page, limit, query) => fetchPaginatedImports(page, limit, query).then(response => ({ data: formatData(response.data), total: response.total }))}
      initialPageSize={pageSize}
    />
  );
});

export default ImportsTable;
