import EmailConfigurationsList from "../../components/EmailConfiguration/EmailConfigurationsList";
import "./EmailConfigurations.css";

function EmailConfigurations() {
  return (
    <>
      <div className="email-configurations-container">
        <h3>Gestion des mails</h3>
        <EmailConfigurationsList />
      </div>
    </>
  );
}

export default EmailConfigurations;
