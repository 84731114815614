import { UserInterface } from "../interfaces/UserInterface";

interface UseUserManagerProps {
  reloadUsers?: () => void;
}

export const useUserManager = ({ reloadUsers }: UseUserManagerProps) => {


  const getHigestCurrentRole = (user:UserInterface | null | undefined):string | null => {
    if(!user?.roles){
      return null;
    }
    if(user.roles.includes('ROLE_ADMIN')){
      return "ROLE_ADMIN";
    }
    if(user.roles.includes('ROLE_ACCOUNT_MANAGER')){
      return "ROLE_ACCOUNT_MANAGER";
    }
    if(user.roles.includes('ROLE_SALES')){
      return "ROLE_SALES";
    }
    return null;
  }
  return {
    getHigestCurrentRole,
  };
};
