import React, { useEffect, useState } from 'react'
import './alert.css'

type AlertProps = {
  message: string
  type: 'error' | 'warning' | 'success'
  duration?: number
  position: number;
}

const Alert: React.FC<AlertProps> = ({ message, type, position, duration = 3000 }) => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(true)
    if (duration !== -1) {
      const timer = setTimeout(() => {
        setShow(false)
      }, duration)

      return () => clearTimeout(timer)
    }
  }, [message, duration])

  const bottomPosition = 4 + 3 * position;

  return (
    <div
      className={`alert alert-${type} ${show ? 'alert-slide-in' : 'alert-slide-out'}`}
      style={{ bottom: `${bottomPosition}em` }}
    >
      {message}
    </div>
  )
}

export default Alert
