import React, { useState } from 'react';
import { useFormContext, RegisterOptions } from 'react-hook-form';

interface CheckboxFieldProps {
  name: string;
  label?: string;
  value: string;
  checked?: boolean;
  disabled?: boolean;
  reverseLabel?: boolean;
  validationRules?: RegisterOptions;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckboxField: React.FC<CheckboxFieldProps> = ({ name, label, value, checked, disabled, reverseLabel, validationRules, onChange }) => {
  const { register, formState: { errors }, setValue, watch } = useFormContext();
  const [isChecked, setIsChecked] = useState<boolean>(checked ?? false);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
    setValue(name, event.target.checked ? value : "");

    if (onChange) {
      onChange(event);
    }
  };
  const errorMessage = (name: string): string | null => {
    const error = errors[name];
    return error && typeof error.message === 'string' ? error.message : null;
  };

  return (
    <div className="form-group">
    {!reverseLabel && (
      <>
        {
          label && (
            <label htmlFor={`${name}-${value}`}>
              {label}
            </label>
          )
        }
        <input
          type="checkbox"
          id={`${name}-${value}`}
          value={value}
          checked={isChecked}
          disabled={disabled}
          {...register(name, validationRules)}
          onChange={handleChange}
        />
      </>
    )}
    {reverseLabel && (
      <>
        <input
          type="checkbox"
          id={`${name}-${value}`}
          value={value}
          checked={isChecked}
          disabled={disabled}
          {...register(name, validationRules)}
          onChange={handleChange}
        />
        {
          label && (
            <label className='reverse' htmlFor={`${name}-${value}`}>
              {label}
            </label>
          )
        }
      </>
    )}
      {errorMessage(name) && <p className="error">{errorMessage(name)}</p>}
    </div>
  );
};

export default CheckboxField;
