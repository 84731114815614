import React from "react";
import { useForm, FormProvider } from "react-hook-form";
import InputField from "../../generic/Form/Fields/Input";
import { PartnerInterface } from "../../../interfaces/PartnerInterface";
import AccountManagerSelect from "../../Manager/Form/AccountManagerSelect";
import SalesRepSelect from "../../Manager/Form/SalesRepSelect";
import SupervisorSelect from "../../Manager/Form/SupervisorSelect";

interface Props {
  partner?: PartnerInterface | null;
  onSubmit: (data: any) => void;
}

interface PartnerFormValues {
  name: string;
  email: string;
  userId: string | null;
  salesRepId: string | null;
  supervisorId: string | null;
  isMajorRetailer: boolean;
  comment: string;
}

const PartnerFormEdit: React.FC<Props> = ({ onSubmit, partner }) => {
  const methods = useForm<PartnerFormValues>({
    defaultValues: {
      name: "",
      email: "",
      userId: null,
      salesRepId: null,
      supervisorId: null,
      isMajorRetailer: false,
      comment: "",
    }
  });

  React.useEffect(() => {
    methods.reset({
      name: !partner ? "" : partner.name,
      email: !partner ? "" : partner.email,
      userId: partner?.manager?.id ? partner.manager.id.toString() : null,
      salesRepId: partner?.salesRep?.id ? partner.salesRep.id.toString() : null,
      supervisorId: partner?.supervisor?.id ? partner.supervisor.id.toString() : null,
      isMajorRetailer: !partner ? false : partner.isMajorRetailer,
      comment: !partner ? "" : partner.comment,
    });
  }, [partner, methods]);

  const handleIsMajorRetailerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    methods.setValue('isMajorRetailer', isChecked);
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField
          name="name"
          label="Nom"
          type="text"
          validationRules={{ required: "Ce champ est requis" }}
        />

        <InputField
          name="email"
          label="Email"
          type="text"
        />

        <AccountManagerSelect
          name="managerId"
          label="Account Manager"
          defaultValue={partner?.manager?.id.toString()}
          validationRules={{ required: "Ce champ est requis" }}
        />

        <SalesRepSelect
          name="salesRepId"
          label="Sales Rep"
          defaultValue={partner?.salesRep?.id.toString()}
          emptyLabel={"---Aucun---"}
        />

        <SupervisorSelect
          name="supervisorId"
          label="Supervisor"
          defaultValue={partner?.supervisor?.id.toString()}
          emptyLabel={"---Aucun---"}
        />
        
        <InputField
          name="comment"
          label="Commentaire"
          type="text"
        />
        
        <div className="buttons-container">
          <button type="submit">Enregistrer</button>
        </div>
      </form>
    </FormProvider>
  );
};

export default PartnerFormEdit;